import { Chart, ChartBar, ChartDonut, ChartGroup, ChartVoronoiContainer } from '@patternfly/react-charts';
import { Breadcrumb, Button, Input, Pagination, Select, Spin } from "antd";
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';

import axios from 'axios';
import ResultAPI from '../../api/results/results.api';
import SYSTEM_CONSTANTS from '../../common/constants';
import { ICriteria } from '../../common/u-innovate/define-criteria';
import { AllTotalResult, CriteriaPoint, SystemStatistics } from '../../common/u-innovate/define-results';
import { getAllQuestionsByCriteriaIdRequest, getStaticsOfResultByCriteriaRequest, getUserInfoRequest } from '../../redux/controller';
import { useDispatchRoot, useSelectorRoot } from '../../redux/store';
import { PolarArea } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    RadialLinearScale,
    ArcElement,
    Tooltip,
    Legend,
    // scales,
} from 'chart.js';
import './styles.judgement.scss';
import ReactApexChart from 'react-apexcharts';
import DoughnutChart from '../../components/Chart/DoughnutChart';
import PolarAreaChart from '../../components/Chart/PolarAreaChart';
import Logo from '../../images/Logo.png';
import RadarChart from '../../components/Chart/RadarChart';
import RadarAmChartDouble from '../../components/Chart/RadarAmChartDouble';

interface MyProps {
    revertToIntro: () => void;

}

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const { Option } = Select;
const colorUINNOVATE = ['#FF3F65', '#FFD0D9'];
const colorUIMPACT = ['#0EE1A7', '#A7F4DF']
const colorPINNOVATE = ['#8961FF', '#DDD2FF']

const CustomTooltip = (active: any, payload: any) => {
    if (active && payload && payload.length) {
        return (
            <div className="custom-tooltip">
                <p className="label">{`${payload[0].value}`}</p>
            </div>
        );
    }
    return null;
};

ChartJS.register(RadialLinearScale, ArcElement, Tooltip, Legend);
// ChartJS.register(RadialLinearScale, ArcElement, Tooltip, Legend);

const lstColor = ['rgba(239, 83, 80, 0.7)', 'rgb(255, 167, 38, 0.7)', 'rgb(255, 213, 79, 0.7)', 'rgb(102, 187, 106, 0.7)', 'rgb(66, 165, 245, 0.7)', 'rgb(92, 107, 192, 0.7)', 'rgb(171, 71, 188, 0.7)', 'rgb(236, 64, 122, 0.7)']
const Result = (props: MyProps) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const { lstQuestionsByCriteria, criteriaLst, tmplstQuestionsByCriteria, tmpResult, criteriaResult } = useSelectorRoot((state) => state.uinnovate);
    const { user } = useSelectorRoot((state) => state.login);
    const [criteriaTotalPoints, setCriteriaTotalPoints] = useState<CriteriaPoint>();
    const [criteriaId, setCriteriaId] = useState<string>('')
    const [maxCountInCriteria, setMaxCountInCriteria] = useState<number>(0);
    const [maxTotalPointAllCriteria, setMaxTotalPointAllCriteria] = useState<number>(0);
    const [tmpCriteriaList, setTmpCriteriaList] = useState<ICriteria[]>([]);
    const [tmpPointsResults, setTmpPointsResults] = useState<any[]>([]);
    const [tmp1PointsResults, setTmp1PointsResults] = useState<any[]>([]);
    const [tmpFiveYearsAgoPointsResults, setTmpFiveYearsAgoPointsResults] = useState<any[]>([]);
    const [tmpSystemStatistics, setTmpSystemStatistics] = useState<any[]>([]);
    const [tmp1SystemStatistics, setTmp1SystemStatistics] = useState<any[]>([]);
    const [tmpFiveYearsAgoSystemStatistics, setTmpFiveYearsAgoSystemStatistics] = useState<any[]>([]);
    const dispatch = useDispatchRoot()
    const [userType, setUserType] = useState<string>('');
    const [widthChart, setWidthChart] = useState<number>(2000);
    const [windowSize, setWindowSize] = useState([window.innerWidth, window.innerHeight]);
    const [width, setWidth] = useState<number>(1400);
    const [widthSubChart, setWidthSubChart] = useState<number>(1400);
    const [radius, setRadius] = useState<number>(60);
    const [isResponsive, setIsResponsive] = useState<boolean>(false);
    const [getNameJudgement, setNameJudgement] = useState<string>('');

    const [pointOfResult, setPointOfResult] = useState<AllTotalResult>();
    const [systemStatistics, setSystemStatistics] = useState<SystemStatistics>();
    const [data, setData] = useState<any[]>([]);
    const [options, setOptions] = useState<any>();
    const [dataDonut, setDataDonut] = useState<any>()
    const [dataDonut2, setDataDonut2] = useState<any>()
    const [optionsDonut, setOptionsDonut] = useState<any>()
    const [optionsDonut2, setOptionsDonut2] = useState<any>()
    const [dataRadio, setDataRadio] = useState<any>()
    const [optionsRadio, setOptionsRadio] = useState<any>()
    const [lstSeries, setLstSeries] = useState<any[]>()
    const [lstData, setLstData] = useState<any>()
    const [criteriaIdForChart, setCriteriaIdForChart] = useState<string>('');
    const [yearSelected, setYearSelected] = useState<any>('2024');

    const [evaluationType, setEvaluationType] = useState('user'); // user or system
    const [selectedCriteria, setSelectedCriteria] = useState('');
    const [selectedYear, setSelectedYear] = useState('');

    const [tmp2PointsResults, setTmp2PointsResults] = useState<any[]>([]);
    const [tmp2SystemStatistics, setTmp2SystemStatistics] = useState<any[]>([]);
    const [tmp3PointsResults, setTmp3PointsResults] = useState<any[]>([]);
    const [tmp3SystemStatistics, setTmp3SystemStatistics] = useState<any[]>([]);
    const [pointOfResult2023, setPointOfResult2023] = useState<AllTotalResult>();
    const [systemStatistics2023, setSystemStatistics2023] = useState<SystemStatistics>();
    const [pointOfResult2024, setPointOfResult2024] = useState<AllTotalResult>();
    const [systemStatistics2024, setSystemStatistics2024] = useState<SystemStatistics>();

    const [currentYear, setCurrentYear] = useState<number>(new Date().getFullYear());
    const [averageOfCriteria, setAverageOfCriteria] = useState<number>(0);
    const [averageOfCriteriaFiveYearsAgo, setAverageOfCriteriaFiveYearsAgo] = useState<number>(0);


    // Add this before the chart
    useEffect(() => {
        fetchDataPointOfResult();
        fetchDataSystemStatistics();
        fetchData();
        fetchDataPointOfResult2023();
        fetchDataSystemStatistics2023();
        fetchDataPointOfResult2024();
        fetchDataSystemStatistics2024();
    }, [])

    useEffect(() => {
        console.log(selectedCriteria);

        console.log(tmp2PointsResults);

        fetchGetChartData(selectedCriteria);


    }, [selectedCriteria])

    useEffect(() => {
        const handleWindowResize = () => {
            setWindowSize([window.innerWidth, window.innerHeight]);
        };

        window.addEventListener('resize', handleWindowResize);
        if (window.innerWidth > 1400) {
            setWidth(400);
            setRadius(60);
            setWidthSubChart(1400);
            setIsResponsive(false);
        }
        if (window.innerWidth <= 1400) {
            setWidth(600);
            setRadius(60);
        }
        if (window.innerWidth <= 1200) {
            setWidth(600);
            setRadius(60);
        }
        if (window.innerWidth <= 400) {
            setWidth(500);
            setRadius(60);
            setIsResponsive(true);
            if (userType === 'UIMPACT') {
                setWidthSubChart(300);
            }
            if (userType === 'UINNOVATE') {
                setWidthSubChart(600);
            }
            if (userType === 'PINNOVATE') {
                setWidthSubChart(300);
            }
        }

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    });
    useEffect(() => {
        console.log(data);
        if (data.length === 0) return

        if (userType === 'UINNOVATE' || userType === 'UINNOVATE_SHORT') {
            const dataSort = data.sort((a: any, b: any) => {
                if (a.criteriaId < b.criteriaId) {
                    return -1;
                }
                if (a.criteriaId > b.criteriaId) {
                    return 1;
                }
                return 0;
            });
            console.log(dataSort);


            const series = dataSort.map((item: any) => item.setOfQuestions)
            console.log(series);

            // Cho series thành mảng 1 chiều
            let series1D: any[] = []
            series.forEach((item: any) => {
                item.forEach((item1: any) => {
                    series1D.push(Math.round(item1.average * 10) / 10)
                })
            })
            console.log(series1D);
            setLstSeries(series1D)

            let labels: any[] = []
            series.forEach((item: any) => {
                item.forEach((item1: any) => {
                    labels.push(item1.setOfQuestionDes)
                })
            })
            console.log(labels);


            // Tạo ra mảng màu theo số lượng series, mỗi series[0], series[1] có 1 màu, còn serti[0][0] sẽ trùng với màu của series[0]
            let colors: string[] = []
            series.forEach((item: any, index: any) => {
                // Lấy ra màu ngẫu nhiêu để cho vào mảng colors
                item.forEach(() => {
                    // colors.push('#FF0000')
                    // colors sẽ lấy ra màu ngẫu nhiên
                    colors.push(lstColor[index])
                })
            })
            console.log(colors);

            const options = {
                responsive: true,
                scales: {
                    r: {
                        suggestedMin: 0,
                        suggestedMax: 5,
                        grid: {
                            circular: true,
                            colors: '#000',
                        },
                        angleLines: {
                            display: true,
                            color: '#00000',
                            lineWidth: 1,
                        },
                        ticks: {
                            stepSize: 1,
                            beginAtZero: true,
                            backdropColor: 'transparent',
                            font: {
                                size: 19,
                            },
                            color: '#000',
                        },
                        lineArc: true,

                    },
                },
                plugins: {
                    legend: {
                        display: false
                    },

                    dataLabels: {
                        // color: '#000',
                        display: false,

                        // formatter: (value: any, context: any) => {
                        //     return ''; // Display the value in the label
                        // },
                    },
                },
                cutout: '50%',
                layout: {
                    padding: 5
                },
                hover: {
                    mode: 'nearest'
                },
                animation: {
                    duration: 2000
                },
                tooltips: {
                    enabled: true,
                },
            };

            const optionsDonut = {
                cutout: '80%', // Adjust the size of the inner hole
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        display: false,
                    },
                    datalabels: {
                        color: (context: any) => {
                            return lstColor[context.dataIndex];
                        },
                        rotation: (context: any) => {
                            switch (context.dataIndex) {
                                case 0:
                                    return 15;
                                case 1:
                                    return 55;
                                case 2:
                                    return 100;
                                case 3:
                                    return 155;
                                case 4:
                                    return 200;
                                case 5:
                                    return 245;
                                case 6:
                                    return 290;
                                case 7:
                                    return 335;
                                default:
                                    return 0;
                            }
                        },
                        display: true,

                        formatter: (value: any, context: any, index: any) => {
                            console.log(context.dataIndex);
                            console.log(dataSort[context.dataIndex].criteriaName);
                            //Kiểm tra nếu
                            let label = dataSort[context.dataIndex].criteriaName;
                            if (label.length > 30) {
                                label = label.substring(0, 30) + '...';
                            }
                            return label; // Display the value in the label
                        },
                        font: {
                            size: windowSize[0] > 400 ? 12 : 6, // Set the font size for data labels
                            fontWeight: 'bold',
                        },
                    },
                },
            }

            const optionsRadio = {
                responsive: true,
                scales: {
                    r: {
                        suggestedMin: 0,
                        suggestedMax: 5,
                        grid: {
                            display: true
                        },
                        angleLines: {
                            display: true
                        },
                        ticks: {
                            display: true
                        },
                        lineArc: true,

                        pointLabels: {
                            display: false, // Ẩn nhãn xung quanh biểu đồ
                        },
                        // grid: {
                        //     display: false, // Tùy chọn: Ẩn các đường lưới
                        // },
                        // angleLines: {
                        //     display: false, // Tùy chọn: Ẩn các đường hướng tâm
                        // },
                        // ticks: {
                        //     display: false, // Tùy chọn: Ẩn các chỉ số mức độ
                        // },

                    },
                },
                plugins: {
                    legend: {
                        display: false
                    },

                    dataLabels: {
                        // color: '#000',
                        display: false,

                        // formatter: (value: any, context: any) => {
                        //     return ''; // Display the value in the label
                        // },
                    },

                },
            }

            const dataTmp = {
                labels: labels,
                datasets: [
                    {
                        label: 'Điểm: ',
                        data: series1D,
                        backgroundColor: colors,
                        borderColor: '#000',
                        borderWidth: 1,
                        innerRadius: 50,
                        indexAxis: 'r',
                    },
                ],
            }

            const dataDonutTmp = {
                labels: dataSort.map((item: any) => item.criteriaName),
                datasets: [
                    {
                        label: 'Điểm: ',
                        data: [4, 5, 5, 6, 5, 5, 5, 6],
                        backgroundColor: ['#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff'],
                        borderColor: '#000',
                        borderWidth: 1,
                        innerRadius: 90,
                        indexAxis: 'r',
                    },
                ],
            }

            const dataDonutTmp2 = {
                labels: dataSort.map((item: any) => item.criteriaName),
                datasets: [
                    {
                        label: 'Điểm: ',
                        data: [1, 1, 1, 1, 1, 1, 1, 1],
                        backgroundColor: ['#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff'],
                        borderColor: '#000',
                        borderWidth: 1,
                        innerRadius: 90,
                        indexAxis: 'r',
                    },
                ],
            }
            console.log(dataDonutTmp);

            const tmpDataRadio: any[] = []

            systemStatistics?.data.listAverageOfCriterias.map((item: any, index: any) => {
                if (item.averageOfCriteria !== 0) {
                    tmpDataRadio.push(Math.round(item.averageOfCriteria * 100) / 100)

                }
                else {
                    tmpDataRadio.push(0)
                }
            })

            const dataRadioTmp = {
                labels: dataSort.map((item: any) => item.criteriaName),
                datasets: [
                    {
                        label: 'Điểm: ',
                        data: tmpDataRadio,
                        backgroundColor: ['#fefdf5', '#fefdf5', '#fefdf5', '#fefdf5', '#fefdf5', '#fefdf5', '#fefdf5', '#fefdf5'],
                        borderColor: '#000',
                        borderWidth: 1,
                        innerRadius: 50,
                        indexAxis: 'r',
                    },
                ],
            }


            setOptions(options)
            setOptionsDonut(optionsDonut)
            setOptionsRadio(optionsRadio)
            setDataDonut(dataDonutTmp)
            setLstData(dataTmp)
            setDataRadio(dataRadioTmp)
            setDataDonut2(dataDonutTmp2)
        }

        else if (userType === 'UIMPACT') {
            const dataSort = data.sort((a: any, b: any) => {
                if (a.criteriaId < b.criteriaId) {
                    return -1;
                }
                if (a.criteriaId > b.criteriaId) {
                    return 1;
                }
                return 0;
            });
            console.log(dataSort[0].criteriaName);


            const series = dataSort.map((item: any) => item.setOfQuestions)
            console.log(series);

            // Cho series thành mảng 1 chiều
            let series1D: any[] = []
            series.forEach((item: any) => {
                item.forEach((item1: any) => {
                    series1D.push(Math.round(item1.average * 10) / 10)
                })
            })
            console.log(series1D);
            setLstSeries(series1D)

            let labels: any[] = []
            series.forEach((item: any) => {
                item.forEach((item1: any) => {
                    labels.push(item1.name)
                })
            })
            console.log(labels);


            // Tạo ra mảng màu theo số lượng series, mỗi series[0], series[1] có 1 màu, còn serti[0][0] sẽ trùng với màu của series[0]
            let colors: string[] = []
            series.forEach((item: any, index: any) => {
                // Lấy ra màu ngẫu nhiêu để cho vào mảng colors
                item.forEach(() => {
                    // colors.push('#FF0000')
                    // colors sẽ lấy ra màu ngẫu nhiên
                    colors.push(lstColor[index])
                })
            })
            console.log(colors);

            const options = {
                responsive: true,
                scales: {
                    r: {
                        suggestedMin: 0,
                        suggestedMax: 5,
                        grid: {
                            circular: true,
                            colors: '#000',
                        },
                        angleLines: {
                            display: true,
                            color: '#00000',
                            lineWidth: 1,
                        },
                        ticks: {
                            stepSize: 1,
                            beginAtZero: true,
                            backdropColor: 'transparent',
                            font: {
                                size: 19,
                            },
                            color: '#000',
                        },
                        lineArc: true,

                    },
                },
                plugins: {
                    legend: {
                        display: false
                    },

                    dataLabels: {
                        // color: '#000',
                        display: false,

                        // formatter: (value: any, context: any) => {
                        //     return ''; // Display the value in the label
                        // },
                    },
                },
                cutout: '50%',
                layout: {
                    padding: 5
                },
                hover: {
                    mode: 'nearest'
                },
                animation: {
                    duration: 2000
                },
                tooltips: {
                    enabled: true,
                },
            };

            const optionsRadio = {
                responsive: true,
                scales: {
                    r: {
                        suggestedMin: 0,
                        suggestedMax: 5,
                        grid: {
                            display: true
                        },
                        angleLines: {
                            display: true
                        },
                        ticks: {
                            display: true
                        },
                        lineArc: true,

                        pointLabels: {
                            display: false, // Ẩn nhãn xung quanh biểu đồ
                        },
                        // grid: {
                        //     display: false, // Tùy chọn: Ẩn các đường lưới
                        // },
                        // angleLines: {
                        //     display: false, // Tùy chọn: Ẩn các đường hướng tâm
                        // },
                        // ticks: {
                        //     display: false, // Tùy chọn: Ẩn các chỉ số mức độ
                        // },

                    },
                },
                plugins: {
                    legend: {
                        display: false
                    },

                    dataLabels: {
                        // color: '#000',
                        display: false,

                        // formatter: (value: any, context: any) => {
                        //     return ''; // Display the value in the label
                        // },
                    },

                },
            }

            const optionsDonut = {
                cutout: '80%', // Adjust the size of the inner hole
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        display: false,
                    },
                    datalabels: {
                        color: (context: any) => {
                            return lstColor[context.dataIndex];
                        },
                        rotation: (context: any) => {
                            switch (context.dataIndex) {
                                case 0:
                                    return 55;
                                case 1:
                                    return 140;
                                case 2:
                                    return 230;
                                case 3:
                                    return 320;
                                default:
                                    return 0;
                            }
                        },
                        display: true,

                        formatter: (value: any, context: any, index: any) => {
                            console.log(context.dataIndex);
                            console.log(dataSort[context.dataIndex].criteriaName);
                            //Kiểm tra nếu
                            let label = dataSort[context.dataIndex].criteriaName;
                            if (label.length > 30) {
                                label = label.substring(0, 30) + '...';
                            }
                            return label; // Display the value in the label
                        },
                        font: {
                            size: windowSize[0] > 400 ? 12 : 6, // Set the font size for data labels
                            fontWeight: 'bold',
                        },
                    },
                },
            }

            const dataTmp = {
                labels: labels,
                datasets: [
                    {
                        label: 'Điểm: ',
                        data: series1D,
                        backgroundColor: colors,
                        borderColor: '#000',
                        borderWidth: 1,
                        innerRadius: 50,
                        indexAxis: 'r',
                    },
                ],
            }

            const dataDonutTmp = {
                labels: dataSort.map((item: any) => item.criteriaName),
                datasets: [
                    {
                        label: 'Điểm: ',
                        data: [series[0].length, series[1].length, series[2].length, series[3].length],
                        backgroundColor: ['#ffffff', '#ffffff', '#ffffff', '#ffffff'],
                        borderColor: '#000',
                        borderWidth: 1,
                        innerRadius: 90,
                        indexAxis: 'r',
                    },
                ],
            }

            const dataDonutTmp2 = {
                labels: dataSort.map((item: any) => item.criteriaName),
                datasets: [
                    {
                        label: 'Điểm: ',
                        data: [1, 1, 1, 1],
                        backgroundColor: ['#ffffff', '#ffffff', '#ffffff', '#ffffff'],
                        borderColor: '#000',
                        borderWidth: 1,
                        innerRadius: 90,
                        indexAxis: 'r',
                    },
                ],
            }

            const tmpDataRadio: any[] = []

            systemStatistics?.data.listAverageOfCriterias.map((item: any, index: any) => {
                console.log(item.averageOfCriteria);
                if (item.averageOfCriteria !== 0) {
                    tmpDataRadio.push(Math.round(item.averageOfCriteria * 100) / 100)

                }
                else {
                    tmpDataRadio.push(0)
                }
            })

            const dataRadioTmp = {
                labels: dataSort.map((item: any) => item.criteriaName),
                datasets: [
                    {
                        label: 'Điểm: ',
                        data: tmpDataRadio,
                        backgroundColor: ['#fefdf5', '#fefdf5', '#fefdf5', '#fefdf5'],
                        borderColor: '#000',
                        borderWidth: 1,
                        innerRadius: 50,
                        indexAxis: 'r',
                    },
                ],
            }



            console.log(dataDonutTmp);


            setOptions(options)
            setOptionsDonut(optionsDonut)
            setDataDonut(dataDonutTmp)
            setDataDonut2(dataDonutTmp2)
            setLstData(dataTmp)
            setDataRadio(dataRadioTmp)
            setOptionsRadio(optionsRadio)
        }

        else if (userType === 'PINNOVATE') {

            const dataSort = data.sort((a: any, b: any) => {
                if (a.criteriaId < b.criteriaId) {
                    return -1;
                }
                if (a.criteriaId > b.criteriaId) {
                    return 1;
                }
                return 0;
            });
            console.log(dataSort[0].criteriaName);


            const series = dataSort.map((item: any) => item.setOfQuestions)
            console.log(series[0].length);

            // Cho series thành mảng 1 chiều
            let series1D: any[] = []
            series.forEach((item: any) => {
                item.forEach((item1: any) => {
                    series1D.push(Math.round(item1.average * 10) / 10)
                })
            })
            console.log(series1D);
            setLstSeries(series1D)

            let labels: any[] = []
            series.forEach((item: any) => {
                item.forEach((item1: any) => {
                    labels.push(item1.name)
                })
            })
            console.log(labels);


            // Tạo ra mảng màu theo số lượng series, mỗi series[0], series[1] có 1 màu, còn serti[0][0] sẽ trùng với màu của series[0]
            let colors: string[] = []
            series.forEach((item: any, index: any) => {
                // Lấy ra màu ngẫu nhiêu để cho vào mảng colors
                item.forEach(() => {
                    // colors.push('#FF0000')
                    // colors sẽ lấy ra màu ngẫu nhiên
                    colors.push(lstColor[index])
                })
            })
            console.log(colors);

            const options = {
                responsive: true,
                scales: {
                    r: {
                        suggestedMin: 0,
                        suggestedMax: 5,
                        grid: {
                            circular: true,
                            colors: '#000',
                        },
                        angleLines: {
                            display: true,
                            color: '#00000',
                            lineWidth: 1,
                        },
                        ticks: {
                            stepSize: 1,
                            beginAtZero: true,
                            backdropColor: 'transparent',
                            font: {
                                size: 19,
                            },
                            color: '#000',
                        },
                        lineArc: true,

                    },
                },
                plugins: {
                    legend: {
                        display: false
                    },

                    dataLabels: {
                        // color: '#000',
                        display: false,

                        // formatter: (value: any, context: any) => {
                        //     return ''; // Display the value in the label
                        // },
                    },
                },
                cutout: '50%',
                layout: {
                    padding: 5
                },
                hover: {
                    mode: 'nearest'
                },
                animation: {
                    duration: 2000
                },
                tooltips: {
                    enabled: true,
                },
            };

            const optionsDonut = {
                cutout: '80%', // Adjust the size of the inner hole
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        display: false,
                    },
                    datalabels: {
                        color: (context: any) => {
                            return lstColor[context.dataIndex];
                        },
                        rotation: (context: any) => {
                            switch (context.dataIndex) {
                                case 0:
                                    return 55;
                                case 1:
                                    return 170;
                                case 2:
                                    return 300;
                                default:
                                    return 0;
                            }
                        },
                        display: true,

                        formatter: (value: any, context: any, index: any) => {
                            console.log(context.dataIndex);
                            console.log(dataSort[context.dataIndex].criteriaName);
                            //Kiểm tra nếu
                            let label = dataSort[context.dataIndex].criteriaName;
                            if (label.length > 30) {
                                label = label.substring(0, 30) + '...';
                            }
                            return label; // Display the value in the label
                        },
                        font: {
                            size: windowSize[0] > 400 ? 12 : 6, // Set the font size for data labels
                            fontWeight: 'bold',
                        },
                    },
                },
            }

            const optionsRadio = {
                responsive: true,
                scales: {
                    r: {
                        suggestedMin: 0,
                        suggestedMax: 5,
                        grid: {
                            display: true
                        },
                        angleLines: {
                            display: true
                        },
                        ticks: {
                            display: true
                        },
                        lineArc: true,

                        pointLabels: {
                            display: false, // Ẩn nhãn xung quanh biểu đồ
                        },
                        // grid: {
                        //     display: false, // Tùy chọn: Ẩn các đường lưới
                        // },
                        // angleLines: {
                        //     display: false, // Tùy chọn: Ẩn các đường hướng tâm
                        // },
                        // ticks: {
                        //     display: false, // Tùy chọn: Ẩn các chỉ số mức độ
                        // },

                    },
                },
                plugins: {
                    legend: {
                        display: false
                    },

                    dataLabels: {
                        // color: '#000',
                        display: false,

                        // formatter: (value: any, context: any) => {
                        //     return ''; // Display the value in the label
                        // },
                    },

                },
            }

            const dataTmp = {
                labels: labels,
                datasets: [
                    {
                        label: 'Điểm: ',
                        data: series1D,
                        backgroundColor: colors,
                        borderColor: '#000',
                        borderWidth: 1,
                        innerRadius: 50,
                        indexAxis: 'r',
                    },
                ],
            }

            const dataDonutTmp = {
                labels: dataSort.map((item: any) => item.criteriaName),
                datasets: [
                    {
                        label: 'Điểm: ',
                        data: [series[0].length, series[1].length, series[2].length],
                        borderColor: '#000',
                        backgroundColor: ['#ffffff', '#ffffff', '#ffffff'],
                        borderWidth: 1,
                        innerRadius: 90,
                        indexAxis: 'r',
                    },
                ],
            }

            const dataDonutTmp2 = {
                labels: dataSort.map((item: any) => item.criteriaName),
                datasets: [
                    {
                        label: 'Điểm: ',
                        data: [1, 1, 1],
                        backgroundColor: ['#ffffff', '#ffffff', '#ffffff'],
                        borderColor: '#000',
                        borderWidth: 1,
                        innerRadius: 90,
                        indexAxis: 'r',
                    },
                ],
            }

            const tmpDataRadio: any[] = []

            systemStatistics?.data.listAverageOfCriterias.map((item: any, index: any) => {
                if (item.averageOfCriteria !== 0) {
                    tmpDataRadio.push(Math.round(item.averageOfCriteria * 100) / 100)
                }
                else {
                    tmpDataRadio.push(0)
                }
            })

            const dataRadioTmp = {
                labels: dataSort.map((item: any) => item.criteriaName),
                datasets: [
                    {
                        label: 'Điểm: ',
                        data: tmpDataRadio,
                        backgroundColor: ['#fefdf5', '#fefdf5', '#fefdf5'],
                        borderColor: '#000',
                        borderWidth: 1,
                        innerRadius: 50,
                        indexAxis: 'r',
                    },
                ],
            }

            console.log(dataDonutTmp);


            setOptions(options)
            setOptionsDonut(optionsDonut)
            setDataDonut(dataDonutTmp)
            setDataDonut2(dataDonutTmp2)
            setLstData(dataTmp)
            setDataRadio(dataRadioTmp)
            setOptionsRadio(optionsRadio)
        }
    }, [data])

    const [doubleChartData, setDoubleChartData] = useState<Array<{
        category: string;
        value1: number;
        value2: number;
    }>>([]);

    useEffect(() => {
        if (!data || !dataRadio) return;

        // Chuyển đổi data cho RadarAmChartDouble
        const chartData = data.map((item: any, index: number) => ({
            category: item.criteriaName,
            value1: dataRadio.datasets[0].data[index], // Data từ radar hiện tại
            value2: item.setOfQuestions.reduce((sum: number, q: any) => sum + q.average, 0) / item.setOfQuestions.length // Data trung bình của biểu đồ đánh giá tổng thể
        }));

        setDoubleChartData(chartData);
    }, [data, dataRadio]);

    useEffect(() => {
        console.log(user);

    }, [user])

    useEffect(() => {
        let type = localStorage.getItem('userType') ? localStorage.getItem('userType') : '';
        let token = localStorage.getItem('token') ? localStorage.getItem('token') : '';
        if (type) {
            type = type.slice(1);
            type = type.slice(0, type.length - 1);

            if (type === 'UINNOVATE_SHORT') {
                type = 'UINNOVATE';
            }
            setUserType(type);

        }
        if (token) {
            token = token.slice(1);
            token = token.slice(0, token.length - 1);
            dispatch(getUserInfoRequest(token))
        }
    }, [])

    useEffect(() => {
        console.log(pointOfResult);
        console.log(systemStatistics);

        if (pointOfResult && systemStatistics) {

            setCurrentYear(new Date(pointOfResult.createdAt).getFullYear());
            setAverageOfCriteria(systemStatistics.data.averageOfTotal);
            setAverageOfCriteriaFiveYearsAgo(systemStatistics.data.averageOfTotalFiveYearsAgo);

            let maxCount = 0;
            pointOfResult.listCriteria.map((item) => {
                if (item.total > maxCount)
                    maxCount = item.total
            })
            setMaxTotalPointAllCriteria(maxCount)
            if (pointOfResult.type === 'UINNOVATE' || pointOfResult.type === 'UINNOVATE_SHORT') {
                let tmp: any[] = [];
                pointOfResult.listCriteria.map((item: any, index: any) => {
                    if (item.total !== 0) {
                        if (!isResponsive) {
                            const req = {
                                name: 'Điểm của bạn',
                                x: item.criteriaName,
                                y: item.total,
                            }
                            tmp.push(req)
                        }
                        else {
                            const req = {
                                name: 'Điểm của bạn',
                                x: 'PD' + (index + 1),
                                y: item.total,
                            }
                            tmp.push(req)
                        }
                    }
                })
                setTmpPointsResults(tmp);

                let tmp1: any[] = [];
                pointOfResult.listCriteria.map((item: any, index: any) => {
                    if (item.total !== 0) {
                        if (!isResponsive) {
                            const req = {
                                name: 'Điểm của bạn',
                                x: item.criteriaName,
                                y: item.averageOfCriteria,
                            }
                            tmp1.push(req)
                        }
                        else {
                            const req = {
                                name: 'Điểm của bạn',
                                x: 'PD' + (index + 1),
                                y: item.averageOfCriteria,
                            }
                            tmp.push(req)
                        }

                    }
                })
                setTmp1PointsResults(tmp1);
                let tmp2: any[] = [];
                systemStatistics?.data.listAverageOfCriterias.map((item: any, index: any) => {
                    if (item.averageOfTotal !== 0) {
                        if (!isResponsive) {
                            const req = {
                                name: 'Điểm TB của hệ thống',
                                x: item.criteriaName,
                                y: Math.round(item.averageOfTotal),
                            }
                            tmp2.push(req)
                        }
                        else {
                            const req = {
                                name: 'Điểm TB của hệ thống',
                                x: 'PD' + (index + 1),
                                y: Math.round(item.averageOfTotal),
                            }
                            tmp2.push(req)
                        }
                    }
                })

                setTmpSystemStatistics(tmp2);

                let tmp3: any[] = [];
                systemStatistics?.data.listAverageOfCriterias.map((item: any, index: any) => {
                    if (item.averageOfCriteria !== 0) {
                        if (!isResponsive) {
                            const req = {
                                name: 'Điểm TB của hệ thống',
                                x: item.criteriaName,
                                y: item.averageOfCriteria,
                            }
                            tmp3.push(req)
                        }
                        else {
                            const req = {
                                name: 'Điểm TB của hệ thống',
                                x: 'PD' + (index + 1),
                                y: item.averageOfCriteria,
                            }
                            tmp3.push(req)
                        }
                    }
                })

                setTmp1SystemStatistics(tmp3);

                setWidthChart(2000);
            }
            if (pointOfResult.type === 'PINNOVATE') {
                let tmp: any[] = [];
                pointOfResult.listCriteria.map((item) => {
                    if (item.total !== 0) {
                        const req = {
                            name: 'Điểm của bạn',
                            x: item.criteriaName,
                            y: item.total,
                        }
                        tmp.push(req)
                    }
                })
                setTmpPointsResults(tmp);

                let tmp1: any[] = [];
                pointOfResult.listCriteria.map((item) => {
                    if (item.total !== 0) {
                        const req = {
                            name: 'Điểm của bạn',
                            x: item.criteriaName,
                            y: item.averageOfCriteria,
                        }
                        tmp1.push(req)
                    }
                })
                setTmp1PointsResults(tmp1);

                let tmp2: any[] = [];
                pointOfResult?.listCriteria.map((item: any) => {
                    if (item.totalFiveYearsAgo !== 0) {
                        const req = {
                            name: 'Điểm của bạn 5 năm trước',
                            x: item.criteriaName,
                            y: Math.round(item.totalFiveYearsAgo),
                        }
                        tmp2.push(req)
                    }
                })

                setTmpSystemStatistics(tmp2);

                let tmp3: any[] = [];
                pointOfResult?.listCriteria.map((item: any) => {
                    if (item.totalFiveYearsAgo !== 0) {
                        const req = {
                            name: 'Điểm của bạn 5 năm trước',
                            x: item.criteriaName,
                            y: item.averageOfCriteriaFiveYearsAgo,
                        }
                        tmp3.push(req)
                    }
                })

                setTmp1SystemStatistics(tmp3);
                setWidthChart(1400);
            }
            if (pointOfResult.type === 'UIMPACT') {
                let tmp: any[] = [];
                let lstCriteriaDescription: any[] = [];
                pointOfResult.listCriteria.map((item) => {
                    if (item.total !== 0) {
                        lstCriteriaDescription.push(item.criteriaDescription)
                    }
                })
                pointOfResult.listCriteria.map((item) => {
                    if (item.total !== 0) {
                        if (!isResponsive) {
                            const req = {
                                name: 'Điểm của bạn',
                                x: `${item.criteriaDescription}: ${item.criteriaName}`,
                                y: item.total,
                            }
                            tmp.push(req)
                        }
                        else {
                            const req = {
                                name: 'Điểm của bạn',
                                x: `${item.criteriaDescription}`,
                                y: item.total,
                            }
                            tmp.push(req)
                        }
                    }
                })
                setTmpPointsResults(tmp);

                let tmp1: any[] = [];
                pointOfResult.listCriteria.map((item) => {
                    if (item.total !== 0) {
                        if (!isResponsive) {
                            const req = {
                                name: 'Điểm của bạn',
                                x: `${item.criteriaDescription}: ${item.criteriaName}`,
                                y: item.averageOfCriteria,
                            }
                            tmp1.push(req)
                        }
                        else {
                            const req = {
                                name: 'Điểm của bạn',
                                x: `${item.criteriaDescription}`,
                                y: item.averageOfCriteria,
                            }
                            tmp1.push(req)
                        }

                    }
                })
                setTmp1PointsResults(tmp1);

                let tmp2: any[] = [];
                let count = 0;
                systemStatistics?.data.listAverageOfCriterias.map((item: any) => {
                    if (item.averageOfTotal !== 0) {
                        if (!isResponsive) {
                            const req = {
                                name: 'Điểm TB của hệ thống',
                                x: `${lstCriteriaDescription[count++]}: ${item.criteriaName}`,
                                y: Math.round(item.averageOfTotal),
                            }
                            tmp2.push(req)
                        }
                        else {
                            const req = {
                                name: 'Điểm TB của hệ thống',
                                x: `${lstCriteriaDescription[count++]}`,
                                y: Math.round(item.averageOfTotal),
                            }
                            tmp2.push(req)
                        }

                    }
                })

                setTmpSystemStatistics(tmp2);

                let tmp3: any[] = [];
                let count1 = 0;
                systemStatistics?.data.listAverageOfCriterias.map((item: any) => {
                    if (item.averageOfCriteria !== 0) {
                        if (!isResponsive) {
                            const req = {
                                name: 'Điểm TB của hệ thống',
                                x: `${lstCriteriaDescription[count1++]}: ${item.criteriaName}`,
                                y: item.averageOfCriteria,
                            }
                            tmp3.push(req)
                        }
                        else {
                            const req = {
                                name: 'Điểm TB của hệ thống',
                                x: `${lstCriteriaDescription[count1++]}`,
                                y: item.averageOfCriteria,
                            }
                            tmp3.push(req)
                        }

                    }
                })

                setTmp1SystemStatistics(tmp3);
                setWidthChart(1400);
            }
        }
    }, [pointOfResult, systemStatistics])

    useEffect(() => {
        fetchGetChartData();
    }, [pointOfResult2023, systemStatistics2023, pointOfResult2024, systemStatistics2024])

    useEffect(() => {
        if (userType === "UIMPACT") {
            let tmp: ICriteria[] = [];
            criteriaLst?.map((item) => {
                if (item.isAnswered && item.numberOfAnswered === item.numberOfQuestion)
                    tmp.push(item)
            })
            setTmpCriteriaList(tmp);

        }
    }, [userType])

    useEffect(() => {
        console.log(criteriaResult);
        if (criteriaResult) {
            let maxCount = 0;
            criteriaResult.map((item) => {
                if (item.count > maxCount)
                    maxCount = item.count
            })
            setMaxCountInCriteria(maxCount)
        }

    }, [criteriaResult])

    useEffect(() => {
        console.log(criteriaId);
        if (criteriaId !== '' && criteriaId !== undefined)
            dispatch(getAllQuestionsByCriteriaIdRequest(criteriaId))
    }, [criteriaId])

    const fetchGetChartData = async (selectedCriteria?: string) => {
        console.log(pointOfResult2023);
        console.log(systemStatistics2023);

        if (pointOfResult2023 && systemStatistics2023) {
            let maxCount = 0;
            pointOfResult2023.listCriteria.map((item) => {
                if (item.total > maxCount)
                    maxCount = item.total
            })
            setMaxTotalPointAllCriteria(maxCount)
            if (pointOfResult2023.type === 'UINNOVATE' || pointOfResult2023.type === 'UINNOVATE_SHORT') {
                let tmp: any[] = [];

                let tmp1: any[] = [];

                if (selectedCriteria) {

                    pointOfResult2023.listCriteria.map((item: any, index: any) => {
                        if (item.criteriaName === selectedCriteria) {
                            console.log('?????', item);
                            if (item.total !== 0) {
                                if (!isResponsive) {
                                    const req = {
                                        name: 'Điểm của bạn',
                                        x: item.criteriaName,
                                        y: item.averageOfCriteria,
                                    }
                                    tmp1.push(req)
                                }
                                else {
                                    const req = {
                                        name: 'Điểm của bạn',
                                        x: 'PD' + (index + 1),
                                        y: item.averageOfCriteria,
                                    }
                                    tmp1.push(req)
                                }
                            }
                        }
                    })
                }
                else {
                    pointOfResult2023.listCriteria.map((item: any, index: any) => {
                        if (item.total !== 0) {
                            if (!isResponsive) {
                                const req = {
                                    name: 'Điểm của bạn',
                                    x: item.criteriaName,
                                    y: item.averageOfCriteria,
                                }
                                tmp1.push(req)
                            }
                            else {
                                const req = {
                                    name: 'Điểm của bạn',
                                    x: 'PD' + (index + 1),
                                    y: item.averageOfCriteria,
                                }
                                tmp.push(req)
                            }

                        }
                    })
                }

                setTmp2PointsResults(tmp1);

                console.log('tmp1', tmp1);


                let tmp3: any[] = [];

                if (selectedCriteria) {
                    systemStatistics2023?.data.listAverageOfCriterias.map((item: any, index: any) => {
                        if (item.criteriaName === selectedCriteria) {
                            if (item.averageOfCriteria !== 0) {
                                if (!isResponsive) {
                                    const req = {
                                        name: 'Điểm TB của hệ thống',
                                        x: item.criteriaName,
                                        y: item.averageOfCriteria,
                                    }
                                    tmp3.push(req)
                                }
                                else {
                                    const req = {
                                        name: 'Điểm TB của hệ thống',
                                        x: 'PD' + (index + 1),
                                        y: item.averageOfCriteria,
                                    }
                                    tmp3.push(req)
                                }
                            }
                        }
                    })
                }

                else {
                    systemStatistics2023?.data.listAverageOfCriterias.map((item: any, index: any) => {
                        if (item.averageOfCriteria !== 0) {
                            if (!isResponsive) {
                                const req = {
                                    name: 'Điểm TB của hệ thống',
                                    x: item.criteriaName,
                                    y: item.averageOfCriteria,
                                }
                                tmp3.push(req)
                            }
                            else {
                                const req = {
                                    name: 'Điểm TB của hệ thống',
                                    x: 'PD' + (index + 1),
                                    y: item.averageOfCriteria,
                                }
                                tmp3.push(req)
                            }
                        }
                    })
                }

                setTmp2SystemStatistics(tmp3);

                console.log('tmp3', tmp3);


                setWidthChart(2000);
            }
            if (pointOfResult2023.type === 'PINNOVATE') {


                let tmp1: any[] = [];

                if (selectedCriteria) {
                    pointOfResult2023.listCriteria.map((item) => {
                        if (item.criteriaName === selectedCriteria) {
                            if (item.total !== 0) {
                                const req = {
                                    name: 'Điểm của bạn',
                                    x: item.criteriaName,
                                    y: item.averageOfCriteria,
                                }
                                tmp1.push(req)
                            }
                        }
                    })
                }

                else {
                    pointOfResult2023.listCriteria.map((item) => {
                        if (item.total !== 0) {
                            const req = {
                                name: 'Điểm của bạn',
                                x: item.criteriaName,
                                y: item.averageOfCriteria,
                            }
                            tmp1.push(req)
                        }
                    })
                }


                setTmp2PointsResults(tmp1);


                let tmp3: any[] = [];

                if (selectedCriteria) {
                    pointOfResult2023?.listCriteria.map((item: any) => {
                        if (item.criteriaName === selectedCriteria) {
                            if (item.totalFiveYearsAgo !== 0) {
                                const req = {
                                    name: 'Điểm của bạn 5 năm trước',
                                    x: item.criteriaName,
                                    y: item.averageOfCriteriaFiveYearsAgo,
                                }
                                tmp3.push(req)
                            }
                        }
                    })

                }
                else {
                    pointOfResult2023?.listCriteria.map((item: any) => {
                        if (item.totalFiveYearsAgo !== 0) {
                            const req = {
                                name: 'Điểm của bạn 5 năm trước',
                                x: item.criteriaName,
                                y: item.averageOfCriteriaFiveYearsAgo,
                            }
                            tmp3.push(req)
                        }
                    })

                }

                setTmp2SystemStatistics(tmp3);
                setWidthChart(1400);
            }
            if (pointOfResult2023.type === 'UIMPACT') {
                let tmp: any[] = [];
                let lstCriteriaDescription: any[] = [];

                console.log('pointOfResult2023', pointOfResult2023);


                let tmp1: any[] = [];

                if (selectedCriteria) {

                    pointOfResult2023.listCriteria.map((item: any) => {
                        if (item.criteriaName === selectedCriteria) {
                            if (item.total !== 0) {
                                if (!isResponsive) {
                                    const req = {
                                        name: 'Điểm của bạn',
                                        x: `${item.criteriaDescription}: ${item.criteriaName}`,
                                        y: item.averageOfCriteria,
                                    }
                                    tmp1.push(req)
                                }
                                else {
                                    const req = {
                                        name: 'Điểm của bạn',
                                        x: `${item.criteriaDescription}`,
                                        y: item.averageOfCriteria,
                                    }
                                    tmp1.push(req)
                                }
                            }
                        }
                    })
                }

                else {

                    pointOfResult2023.listCriteria.map((item) => {
                        if (item.total !== 0) {
                            if (!isResponsive) {
                                const req = {
                                    name: 'Điểm của bạn',
                                    x: `${item.criteriaDescription}: ${item.criteriaName}`,
                                    y: item.averageOfCriteria,
                                }
                                tmp1.push(req)
                            }
                            else {
                                const req = {
                                    name: 'Điểm của bạn',
                                    x: `${item.criteriaDescription}`,
                                    y: item.averageOfCriteria,
                                }
                                tmp1.push(req)
                            }

                        }
                    })
                }

                setTmp2PointsResults(tmp1);



                let tmp3: any[] = [];
                let count1 = 0;

                if (selectedCriteria) {

                    systemStatistics2023?.data.listAverageOfCriterias.map((item: any) => {
                        if (item.criteriaName === selectedCriteria) {
                            if (item.averageOfCriteria !== 0) {
                                if (!isResponsive) {
                                    const req = {
                                        name: 'Điểm TB của hệ thống',
                                        x: `${item.criteriaDescription}: ${item.criteriaName}`,
                                        y: item.averageOfCriteria,
                                    }
                                    tmp3.push(req)
                                }
                                else {
                                    const req = {
                                        name: 'Điểm TB của hệ thống',
                                        x: `${item.criteriaDescription}`,
                                        y: item.averageOfCriteria,
                                    }
                                    tmp3.push(req)
                                }
                            }
                        }
                    })

                }

                else {
                    systemStatistics2023?.data.listAverageOfCriterias.map((item: any) => {
                        if (item.averageOfCriteria !== 0) {
                            if (!isResponsive) {
                                const req = {
                                    name: 'Điểm TB của hệ thống',
                                    x: `${lstCriteriaDescription[count1++]}: ${item.criteriaName}`,
                                    y: item.averageOfCriteria,
                                }
                                tmp3.push(req)
                            }
                            else {
                                const req = {
                                    name: 'Điểm TB của hệ thống',
                                    x: `${lstCriteriaDescription[count1++]}`,
                                    y: item.averageOfCriteria,
                                }
                                tmp3.push(req)
                            }

                        }
                    })
                }

                setTmp2SystemStatistics(tmp3);
                setWidthChart(1400);
            }
        }

        if (pointOfResult2024 && systemStatistics2024) {
            let maxCount = 0;
            pointOfResult2024.listCriteria.map((item) => {
                if (item.total > maxCount)
                    maxCount = item.total
            })
            setMaxTotalPointAllCriteria(maxCount)
            if (pointOfResult2024.type === 'UINNOVATE' || pointOfResult2024.type === 'UINNOVATE_SHORT') {
                let tmp: any[] = [];

                let tmp1: any[] = [];

                if (selectedCriteria) {

                    pointOfResult2024.listCriteria.map((item: any, index: any) => {
                        if (item.criteriaName === selectedCriteria) {
                            console.log('?????', item);
                            if (item.total !== 0) {
                                if (!isResponsive) {
                                    const req = {
                                        name: 'Điểm của bạn',
                                        x: item.criteriaName,
                                        y: item.averageOfCriteria,
                                    }
                                    tmp1.push(req)
                                }
                                else {
                                    const req = {
                                        name: 'Điểm của bạn',
                                        x: 'PD' + (index + 1),
                                        y: item.averageOfCriteria,
                                    }
                                    tmp1.push(req)
                                }
                            }
                        }
                    })
                }
                else {
                    pointOfResult2024.listCriteria.map((item: any, index: any) => {
                        if (item.total !== 0) {
                            if (!isResponsive) {
                                const req = {
                                    name: 'Điểm của bạn',
                                    x: item.criteriaName,
                                    y: item.averageOfCriteria,
                                }
                                tmp1.push(req)
                            }
                            else {
                                const req = {
                                    name: 'Điểm của bạn',
                                    x: 'PD' + (index + 1),
                                    y: item.averageOfCriteria,
                                }
                                tmp.push(req)
                            }

                        }
                    })
                }

                setTmp3PointsResults(tmp1);

                console.log('tmp1', tmp1);


                let tmp3: any[] = [];

                if (selectedCriteria) {
                    systemStatistics2024?.data.listAverageOfCriterias.map((item: any, index: any) => {
                        if (item.criteriaName === selectedCriteria) {
                            if (item.averageOfCriteria !== 0) {
                                if (!isResponsive) {
                                    const req = {
                                        name: 'Điểm TB của hệ thống',
                                        x: item.criteriaName,
                                        y: item.averageOfCriteria,
                                    }
                                    tmp3.push(req)
                                }
                                else {
                                    const req = {
                                        name: 'Điểm TB của hệ thống',
                                        x: 'PD' + (index + 1),
                                        y: item.averageOfCriteria,
                                    }
                                    tmp3.push(req)
                                }
                            }
                        }
                    })
                }

                else {
                    systemStatistics2024?.data.listAverageOfCriterias.map((item: any, index: any) => {
                        if (item.averageOfCriteria !== 0) {
                            if (!isResponsive) {
                                const req = {
                                    name: 'Điểm TB của hệ thống',
                                    x: item.criteriaName,
                                    y: item.averageOfCriteria,
                                }
                                tmp3.push(req)
                            }
                            else {
                                const req = {
                                    name: 'Điểm TB của hệ thống',
                                    x: 'PD' + (index + 1),
                                    y: item.averageOfCriteria,
                                }
                                tmp3.push(req)
                            }
                        }
                    })
                }

                setTmp3SystemStatistics(tmp3);

                console.log('tmp3', tmp3);


                setWidthChart(2000);
            }
            if (pointOfResult2024.type === 'PINNOVATE') {


                let tmp1: any[] = [];

                if (selectedCriteria) {
                    pointOfResult2024.listCriteria.map((item) => {
                        if (item.criteriaName === selectedCriteria) {
                            if (item.total !== 0) {
                                const req = {
                                    name: 'Điểm của bạn',
                                    x: item.criteriaName,
                                    y: item.averageOfCriteria,
                                }
                                tmp1.push(req)
                            }
                        }
                    })
                }

                else {
                    pointOfResult2024.listCriteria.map((item) => {
                        if (item.total !== 0) {
                            const req = {
                                name: 'Điểm của bạn',
                                x: item.criteriaName,
                                y: item.averageOfCriteria,
                            }
                            tmp1.push(req)
                        }
                    })
                }


                setTmp3PointsResults(tmp1);


                let tmp3: any[] = [];

                if (selectedCriteria) {
                    pointOfResult2024?.listCriteria.map((item: any) => {
                        if (item.criteriaName === selectedCriteria) {
                            if (item.totalFiveYearsAgo !== 0) {
                                const req = {
                                    name: 'Điểm của bạn 5 năm trước',
                                    x: item.criteriaName,
                                    y: item.averageOfCriteriaFiveYearsAgo,
                                }
                                tmp3.push(req)
                            }
                        }
                    })

                }
                else {
                    pointOfResult2024?.listCriteria.map((item: any) => {
                        if (item.totalFiveYearsAgo !== 0) {
                            const req = {
                                name: 'Điểm của bạn 5 năm trước',
                                x: item.criteriaName,
                                y: item.averageOfCriteriaFiveYearsAgo,
                            }
                            tmp3.push(req)
                        }
                    })

                }

                setTmp3SystemStatistics(tmp3);
                setWidthChart(1400);
            }
            if (pointOfResult2024.type === 'UIMPACT') {
                let tmp: any[] = [];
                let lstCriteriaDescription: any[] = [];


                let tmp1: any[] = [];

                if (selectedCriteria) {

                    pointOfResult2024.listCriteria.map((item: any) => {
                        if (item.criteriaName === selectedCriteria) {
                            if (item.total !== 0) {
                                if (!isResponsive) {
                                    const req = {
                                        name: 'Điểm của baise',
                                        x: `${item.criteriaDescription}: ${item.criteriaName}`,
                                        y: item.averageOfCriteria,
                                    }
                                    tmp1.push(req)
                                }
                                else {
                                    const req = {
                                        name: 'Điểm của baise',
                                        x: `${item.criteriaDescription}`,
                                        y: item.averageOfCriteria,
                                    }
                                    tmp1.push(req)
                                }
                            }
                        }
                    })
                }

                else {

                    pointOfResult2024.listCriteria.map((item) => {
                        if (item.total !== 0) {
                            if (!isResponsive) {
                                const req = {
                                    name: 'Điểm của bạn',
                                    x: `${item.criteriaDescription}: ${item.criteriaName}`,
                                    y: item.averageOfCriteria,
                                }
                                tmp1.push(req)
                            }
                            else {
                                const req = {
                                    name: 'Điểm của bạn',
                                    x: `${item.criteriaDescription}`,
                                    y: item.averageOfCriteria,
                                }
                                tmp1.push(req)
                            }

                        }
                    })
                }

                setTmp3PointsResults(tmp1);



                let tmp3: any[] = [];
                let count1 = 0;

                if (selectedCriteria) {

                    systemStatistics2024?.data.listAverageOfCriterias.map((item: any) => {
                        if (item.criteriaName === selectedCriteria) {
                            if (item.averageOfCriteria !== 0) {
                                if (!isResponsive) {
                                    const req = {
                                        name: 'Điểm TB của hệ thống',
                                        x: `${item.criteriaDescription}: ${item.criteriaName}`,
                                        y: item.averageOfCriteria,
                                    }
                                    tmp3.push(req)
                                }
                                else {
                                    const req = {
                                        name: 'Điểm TB của hệ thống',
                                        x: `${item.criteriaDescription}`,
                                        y: item.averageOfCriteria,
                                    }
                                    tmp3.push(req)
                                }
                            }
                        }
                    })

                }

                else {
                    systemStatistics2024?.data.listAverageOfCriterias.map((item: any) => {
                        if (item.averageOfCriteria !== 0) {
                            if (!isResponsive) {
                                const req = {
                                    name: 'Điểm TB của hệ thống',
                                    x: `${lstCriteriaDescription[count1++]}: ${item.criteriaName}`,
                                    y: item.averageOfCriteria,
                                }
                                tmp3.push(req)
                            }
                            else {
                                const req = {
                                    name: 'Điểm TB của hệ thống',
                                    x: `${lstCriteriaDescription[count1++]}`,
                                    y: item.averageOfCriteria,
                                }
                                tmp3.push(req)
                            }

                        }
                    })
                }

                setTmp3SystemStatistics(tmp3);
                setWidthChart(1400);
            }
        }
    }

    const fetchDataPointOfResult = async () => {
        let checkLogin = localStorage.getItem('token') ? localStorage.getItem('token') : ''
        if (checkLogin) {
            checkLogin = checkLogin.slice(1);
            checkLogin = checkLogin.slice(0, checkLogin.length - 1);
        }
        await axios.get(`${ResultAPI.host}/${SYSTEM_CONSTANTS.API.RESULT.GETSTATICSOFRESULTBYUSER}`, {
            headers: {
                Authorization: `Bearer ${checkLogin}`
            }
        })
            .then((res) => {
                setPointOfResult(res.data.data)
            })
            .catch((err) => {
                console.log(err);
            })
    }

    const fetchDataPointOfResult2024 = async () => {
        let checkLogin = localStorage.getItem('token') ? localStorage.getItem('token') : ''
        if (checkLogin) {
            checkLogin = checkLogin.slice(1);
            checkLogin = checkLogin.slice(0, checkLogin.length - 1);
        }
        await axios.get(`${ResultAPI.host}/${SYSTEM_CONSTANTS.API.RESULT.GETSTATICSOFRESULTBYUSER}?year=2024`, {
            headers: {
                Authorization: `Bearer ${checkLogin}`
            }
        })
            .then((res) => {
                setPointOfResult2024(res.data.data)
            })
            .catch((err) => {
                console.log(err);
            })
    }

    const fetchDataPointOfResult2023 = async () => {
        let checkLogin = localStorage.getItem('token') ? localStorage.getItem('token') : ''
        if (checkLogin) {
            checkLogin = checkLogin.slice(1);
            checkLogin = checkLogin.slice(0, checkLogin.length - 1);
        }
        await axios.get(`${ResultAPI.host}/${SYSTEM_CONSTANTS.API.RESULT.GETSTATICSOFRESULTBYUSER}?year=2023`, {
            headers: {
                Authorization: `Bearer ${checkLogin}`
            }
        })
            .then((res) => {
                setPointOfResult2023(res.data.data)
            })
            .catch((err) => {
                console.log(err);
            })
    }

    const fetchDataSystemStatistics = async () => {
        let checkLogin = localStorage.getItem('token') ? localStorage.getItem('token') : ''
        if (checkLogin) {
            checkLogin = checkLogin.slice(1);
            checkLogin = checkLogin.slice(0, checkLogin.length - 1);
        }
        await axios.get(`${ResultAPI.host}/${SYSTEM_CONSTANTS.API.RESULT.GET_SYSTEM_STATISTICS}`, {
            headers: {
                Authorization: `Bearer ${checkLogin}`
            }
        })
            .then((res) => {
                console.log(res.data.data);
                setSystemStatistics(res.data.data)
            })
            .catch((err) => {
                console.log(err);
            })
    }

    const fetchDataSystemStatistics2024 = async () => {
        let checkLogin = localStorage.getItem('token') ? localStorage.getItem('token') : ''
        if (checkLogin) {
            checkLogin = checkLogin.slice(1);
            checkLogin = checkLogin.slice(0, checkLogin.length - 1);
        }
        await axios.get(`${ResultAPI.host}/${SYSTEM_CONSTANTS.API.RESULT.GET_SYSTEM_STATISTICS}?year=2024`, {
            headers: {
                Authorization: `Bearer ${checkLogin}`
            }
        })
            .then((res) => {
                console.log(res.data.data);
                setSystemStatistics2024(res.data.data)
            })
            .catch((err) => {
                console.log(err);
            })
    }

    const fetchDataSystemStatistics2023 = async () => {
        let checkLogin = localStorage.getItem('token') ? localStorage.getItem('token') : ''
        if (checkLogin) {
            checkLogin = checkLogin.slice(1);
            checkLogin = checkLogin.slice(0, checkLogin.length - 1);
        }
        await axios.get(`${ResultAPI.host}/${SYSTEM_CONSTANTS.API.RESULT.GET_SYSTEM_STATISTICS}?year=2023`, {
            headers: {
                Authorization: `Bearer ${checkLogin}`
            }
        })
            .then((res) => {
                console.log(res.data.data);
                setSystemStatistics2023(res.data.data)
            })
            .catch((err) => {
                console.log(err);
            })
    }

    const fetchData = async () => {
        let checkLogin = localStorage.getItem('token') ? localStorage.getItem('token') : ''
        if (checkLogin) {
            checkLogin = checkLogin.replace(/"/g, '');
        }

        console.log(checkLogin);

        await axios.post(`${ResultAPI.host}/${SYSTEM_CONSTANTS.API.RESULT.GET_DETAIL_USER}?type=${user?.type}&year=${yearSelected}`,
            {

            },
            {
                headers: {
                    acccept: 'application/json',
                    Authorization: `Bearer ${checkLogin}`
                }
            })
            .then((res) => {
                console.log(res.data.data);
                const dataSort = res.data.data.sort((a: any, b: any) => {
                    if (a.criteriaId < b.criteriaId) {
                        return -1;
                    }
                    if (a.criteriaId > b.criteriaId) {
                        return 1;
                    }
                    return 0;
                });

                console.log(dataSort.length);

                setData(res.data.data)
            })
            .catch((err) => {
                console.log(err);
            })
    }

    const handlePageChange = (page: number) => {
        setCurrentIndex(page - 1);
    };

    const handleOnChangeCriteria = (val: string) => {
        console.log(val);
        setCurrentIndex(0);
        
        
        const criteriaOfPoint = pointOfResult?.listCriteria.find((item) => item.criteriaId === val);
        val === undefined ? setCriteriaId('') : setCriteriaId(val)
        console.log('criteriaOfPoint', criteriaOfPoint);
        setCriteriaTotalPoints(criteriaOfPoint)
        if (val !== undefined && val !== '')
            dispatch(getStaticsOfResultByCriteriaRequest(val))
    }

    const handleClickButton = () => {
        const criteriaOfPoint = pointOfResult?.listCriteria.find((item) => item.criteriaId === undefined);
        setCriteriaId('')
        setCriteriaTotalPoints(criteriaOfPoint)
        // dispatch(getStaticsOfResultByCriteriaRequest(''))
    }

    const getExpertisePoint = (point: number) => {
        if (point <= 12.5) {
            return 'rất yếu';
        } else if (point <= 25) {
            return 'yếu';
        } else if (point <= 37.5) {
            return 'dưới trung bình';
        } else if (point <= 50) {
            return 'trung bình';
        } else if (point <= 62.5) {
            return 'khá';
        } else if (point <= 75) {
            return 'tốt';
        } else if (point <= 87.5) {
            return 'rất tốt';
        } else {
            return 'xuất sắc';
        }
    }

    return (
        <div className='result-answer'>
            <Breadcrumb>
                <Breadcrumb.Item>
                    <a onClick={() => props.revertToIntro()}>Đánh giá</a>
                </Breadcrumb.Item>
                <Breadcrumb.Item className='present-link'>
                    Kết quả
                </Breadcrumb.Item>
            </Breadcrumb>
            <div className='turn-back-btn' >
                {(criteriaLst && userType !== "UIMPACT") &&
                    <div className='select-option'>
                        <Select
                            id='select-criteria'
                            className='select-criteria'
                            placeholder="Chọn phương diện"
                            onChange={handleOnChangeCriteria}
                            value={criteriaId ? criteriaId : 'Chọn phương diện'}
                        >
                            {criteriaLst.map((index) => (
                                <Option value={index.criteriaId}>{index.name}</Option>
                            ))}
                        </Select>
                        <motion.div className='home-button'
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.95 }}>
                            <Button onClick={handleClickButton}>Xóa lựa chọn</Button>
                        </motion.div>
                    </div>
                }

                {(criteriaLst && userType === "UIMPACT") &&
                    <div className='select-option'>
                        <Select
                            className='select-criteria'
                            placeholder="Chọn mục tiêu"
                            onChange={handleOnChangeCriteria}
                            value={criteriaId ? criteriaId : 'Chọn mục tiêu'}
                        >
                            {tmpCriteriaList.map((index) => (
                                <Option value={index.criteriaId}>{index.name}</Option>
                            ))}
                        </Select>
                        <motion.div className='home-button'
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.95 }}>
                            <Button onClick={handleClickButton}>Xóa lựa chọn</Button>
                        </motion.div>
                    </div>
                }

            </div>
            <div className={'total-score ' + ((pointOfResult && criteriaResult[0] && criteriaId && criteriaTotalPoints) ? 'select' : ' ')}>
                <div className='total-score-left'>
                    {/* <div className='title' >Kết quả đánh giá của bạn!</div> */}
                    {/* <div className='sub-title'>Lorem ipsum dolor sit amet consectetur. Ut amet a amet lacinia etiam integer urna pharetra. Malesuada tristique volutpat semper pharetra mauris dis.</div> */}
                    <div className='detail-total'>
                        {(pointOfResult && !criteriaId) &&
                            <div className='detail-content'>
                                {(userType === 'UINNOVATE' || userType === 'UIMPACT') && <h5> Kết quả đánh giá trường đại học khởi nghiệp</h5>}
                                {userType === 'PINNOVATE' && <h5> Kết quả đánh giá địa phương</h5>}
                                {((userType === 'UINNOVATE' || userType === 'UIMPACT') && user) &&
                                    <div className='subtitle bold'>Trường được đánh giá: {user.facility.name}</div>
                                }
                                {(userType === 'PINNOVATE' && user && user.address) &&
                                    <div className='subtitle bold'>Địa phương được đánh giá: {user.address.name}</div>
                                }
                                <div className='subtitle'>Tổng số người đã tham gia đánh giá:<b> {systemStatistics?.data.totalOfResult}</b></div>
                                {/* <div className='subtitle'>Xếp hạng tổng điểm của bạn trong cùng tổ chức: <b> {systemStatistics?.ranking}</b></div> */}
                                {/* <div className='subtitle last'>Xếp hạng điểm trung bình của bạn trong cùng tổ chức:<b> {systemStatistics?.ranking}</b></div> */}
                                <div className='content-chart'>
                                    {userType === "UINNOVATE" &&
                                        <div style={{
                                            display: 'flex',
                                        }}>
                                            <ChartDonut
                                                constrainToVisibleArea={true}
                                                data={[
                                                    { x: 'Tổng điểm hiện tại của bạn ', y: pointOfResult?.total },
                                                    { x: 'Tổng điểm tối đa của bảng hỏi', y: pointOfResult?.maxPoint - pointOfResult?.total },
                                                ]}
                                                legendData={[
                                                    { name: `Tổng điểm hiện tại của bạn: ${pointOfResult?.total} điểm` },
                                                    { name: `Tổng điểm tối đa của bảng hỏi: ${pointOfResult?.maxPoint} điểm` }
                                                ]}
                                                legendOrientation="vertical"
                                                legendPosition="right"
                                                padding={{
                                                    bottom: 0,
                                                    left: 0,
                                                    right: 260, // Adjusted to accommodate legend
                                                    top: 0
                                                }}
                                                subTitle={`trên tổng ${pointOfResult?.maxPoint}`}
                                                title={pointOfResult?.total.toString()}
                                                colorScale={colorUINNOVATE}
                                                // themeColor={ChartThemeColor.multiUnordered}
                                                width={width}
                                                radius={radius}
                                            />
                                            <ChartDonut
                                                constrainToVisibleArea={true}
                                                data={[
                                                    { x: 'Điểm trung bình của hệ thống', y: averageOfCriteria },
                                                    { x: 'Tổng điểm tối đa của bảng hỏi', y: pointOfResult?.maxPoint - pointOfResult?.total },
                                                ]}
                                                legendData={[
                                                    { name: `Điểm trung bình của hệ thống: ${averageOfCriteria} điểm` },
                                                    { name: `Tổng điểm tối đa của bảng hỏi: ${pointOfResult?.maxPoint} điểm` }
                                                ]}
                                                legendOrientation="vertical"
                                                legendPosition="right"
                                                padding={{
                                                    bottom: 0,
                                                    left: 0,
                                                    right: 260, // Adjusted to accommodate legend
                                                    top: 0
                                                }}
                                                subTitle={`trên tổng ${pointOfResult?.maxPoint}`}
                                                title={averageOfCriteria.toString()}
                                                colorScale={colorUINNOVATE}
                                                // themeColor={ChartThemeColor.multiUnordered}
                                                width={width}
                                                radius={radius}
                                            />
                                        </div>
                                    }
                                    {userType === "UIMPACT" &&
                                        <div style={{
                                            display: 'flex',
                                        }}>
                                            <ChartDonut
                                                constrainToVisibleArea={true}
                                                data={[
                                                    { x: 'Tổng điểm hiện tại của bạn', y: pointOfResult?.total },
                                                    { x: 'Tổng điểm tối đa của bảng hỏi', y: pointOfResult?.maxPoint - pointOfResult?.total },
                                                ]}
                                                legendData={[
                                                    { name: `Tổng điểm hiện tại của bạn: ${pointOfResult?.total} điểm` },
                                                    { name: `Tổng điểm tối đa của bảng hỏi: ${pointOfResult?.maxPoint} điểm` }
                                                ]}
                                                legendOrientation="vertical"
                                                legendPosition="right"
                                                padding={{
                                                    bottom: 20,
                                                    left: 20,
                                                    right: 260, // Adjusted to accommodate legend
                                                    top: 20
                                                }}
                                                subTitle={`trên tổng ${pointOfResult?.maxPoint}`}
                                                title={pointOfResult?.total.toString()}
                                                colorScale={colorUIMPACT}
                                                // themeColor={ChartThemeColor.multiUnordered}
                                                width={width}
                                                radius={radius}
                                            />
                                            <ChartDonut
                                                constrainToVisibleArea={true}
                                                data={[
                                                    { x: 'Điểm trung bình của hệ thống', y: averageOfCriteria },
                                                    { x: 'Tổng điểm tối đa của bảng hỏi', y: pointOfResult?.maxPoint - pointOfResult?.total },
                                                ]}
                                                legendData={[
                                                    { name: `Điểm trung bình của hệ thống: ${averageOfCriteria} điểm` },
                                                    { name: `Tổng điểm tối đa của bảng hỏi: ${pointOfResult?.maxPoint} điểm` }
                                                ]}
                                                legendOrientation="vertical"
                                                legendPosition="right"
                                                padding={{
                                                    bottom: 20,
                                                    left: 20,
                                                    right: 260, // Adjusted to accommodate legend
                                                    top: 20
                                                }}
                                                subTitle={`trên tổng ${pointOfResult?.maxPoint}`}
                                                title={averageOfCriteria.toString()}
                                                colorScale={colorUIMPACT}
                                                // themeColor={ChartThemeColor.multiUnordered}
                                                width={width}
                                                radius={radius}
                                            />
                                        </div>

                                    }
                                    {userType === "PINNOVATE" &&
                                        <div>

                                            <div style={{
                                                display: 'flex',
                                            }}>
                                                <ChartDonut
                                                    constrainToVisibleArea={true}
                                                    data={[
                                                        { x: 'Tổng điểm hiện tại của bạn', y: pointOfResult?.total },
                                                        { x: 'Tổng điểm tối đa của bảng hỏi', y: pointOfResult?.maxPoint - pointOfResult?.total },
                                                    ]}
                                                    legendData={[
                                                        { name: `Tổng điểm hiện tại của bạn: ${pointOfResult?.total} điểm` },
                                                        { name: `Tổng điểm tối đa của bảng hỏi: ${pointOfResult?.maxPoint} điểm` }
                                                    ]}
                                                    legendOrientation="vertical"
                                                    legendPosition="right"
                                                    padding={{
                                                        bottom: 0,
                                                        left: 20,
                                                        right: 260, // Adjusted to accommodate legend
                                                        top: 0
                                                    }}
                                                    subTitle={`trên tổng ${pointOfResult?.maxPoint}`}
                                                    title={pointOfResult?.total.toString()}
                                                    colorScale={colorPINNOVATE}
                                                    // themeColor={ChartThemeColor.multiUnordered}
                                                    width={width}
                                                    radius={radius}
                                                />
                                                <ChartDonut
                                                    constrainToVisibleArea={true}
                                                    data={[
                                                        { x: 'Tổng điểm 5 năm trước', y: pointOfResult?.totalFiveYearsAgo },
                                                        { x: 'Tổng điểm tối đa 5 năm trước', y: pointOfResult?.maxPointFiveYearsAgo },
                                                    ]}
                                                    legendData={[
                                                        { name: `Tổng điểm 5 năm trước: ${pointOfResult?.totalFiveYearsAgo} điểm` },
                                                        { name: `Tổng điểm tối đa 5 năm trước: ${pointOfResult?.maxPointFiveYearsAgo} điểm` }
                                                    ]}
                                                    legendOrientation="vertical"
                                                    legendPosition="right"
                                                    padding={{
                                                        bottom: 0,
                                                        left: 20,
                                                        right: 260, // Adjusted to accommodate legend
                                                        top: 0
                                                    }}
                                                    subTitle={`trên tổng ${pointOfResult?.maxPoint}`}
                                                    title={pointOfResult?.totalFiveYearsAgo.toString()}
                                                    colorScale={colorPINNOVATE}
                                                    // themeColor={ChartThemeColor.multiUnordered}
                                                    width={width}
                                                    radius={radius}
                                                />
                                            </div>
                                            <div style={{
                                                display: 'flex',
                                            }}>
                                                <ChartDonut
                                                    constrainToVisibleArea={true}
                                                    data={[
                                                        { x: 'Tổng điểm trung bình của hệ thống', y: averageOfCriteria },
                                                        { x: 'Tổng điểm tối đa của bảng hỏi', y: pointOfResult?.maxPoint - pointOfResult?.total },
                                                    ]}
                                                    legendData={[
                                                        { name: `Điểm trung bình của hệ thống: ${averageOfCriteria} điểm` },
                                                        { name: `Tổng điểm tối đa của bảng hỏi: ${pointOfResult?.maxPoint} điểm` }
                                                    ]}
                                                    legendOrientation="vertical"
                                                    legendPosition="right"
                                                    padding={{
                                                        bottom: 0,
                                                        left: 20,
                                                        right: 260, // Adjusted to accommodate legend
                                                        top: 0
                                                    }}
                                                    subTitle={`trên tổng ${pointOfResult?.maxPoint}`}
                                                    title={averageOfCriteria.toString()}
                                                    colorScale={colorPINNOVATE}
                                                    // themeColor={ChartThemeColor.multiUnordered}
                                                    width={width}
                                                    radius={radius}
                                                />
                                                <ChartDonut
                                                    constrainToVisibleArea={true}
                                                    data={[
                                                        { x: 'Tổng điểm trung bình của hệ thống 5 năm trước', y: averageOfCriteriaFiveYearsAgo },
                                                        { x: 'Tổng điểm tối đa 5 năm trước', y: pointOfResult?.maxPointFiveYearsAgo },
                                                    ]}
                                                    legendData={[
                                                        { name: `Điểm trung bình của hệ thống 5 năm trước: ${averageOfCriteriaFiveYearsAgo} điểm` },
                                                        { name: `Tổng điểm tối đa 5 năm trước: ${pointOfResult?.maxPointFiveYearsAgo} điểm` }
                                                    ]}
                                                    legendOrientation="vertical"
                                                    legendPosition="right"
                                                    padding={{
                                                        bottom: 0,
                                                        left: 20,
                                                        right: 260, // Adjusted to accommodate legend
                                                        top: 0
                                                    }}
                                                    subTitle={`trên tổng ${pointOfResult?.maxPoint}`}
                                                    title={averageOfCriteriaFiveYearsAgo.toString()}
                                                    colorScale={colorPINNOVATE}
                                                    // themeColor={ChartThemeColor.multiUnordered}
                                                    width={width}
                                                    radius={radius}
                                                />
                                            </div>
                                        </div>

                                    }
                                </div>
                                {
                                    userType === "UINNOVATE" &&
                                    <div
                                        className='subtitle'
                                        style={{
                                            textAlign: 'center'
                                        }}
                                    >Với tổng điểm của bạn chiếm <strong className='strong'>{Math.round((pointOfResult?.total / (pointOfResult?.maxPoint)) * 100)}%</strong> so với số điểm tối đa, tham chiếu các yêu cầu của một trường đại học đổi mới sáng tạo và khởi nghiệp với tiêu chuẩn quốc tế, Trường anh chị đang ở mức độ <strong>{getExpertisePoint(Math.round((pointOfResult?.total / (pointOfResult?.maxPoint)) * 100))}</strong> </div>
                                }
                            </div>

                        }
                        {(pointOfResult && criteriaResult[0] && criteriaId && criteriaTotalPoints) &&
                            <div className='detail-content'>
                                <h5>Tổng điểm vấn đề <i>{criteriaTotalPoints.criteriaName}</i></h5>
                                <div className='subtitle criteria'>Điểm trung bình của toàn bộ bảng hỏi:<b> {criteriaTotalPoints.averageOfCriteria?.toFixed(2)}</b></div>
                                {userType === 'UINNOVATE' &&
                                    <ChartDonut
                                        constrainToVisibleArea={true}
                                        data={[{ x: 'Tổng điểm hiện tại của bạn', y: criteriaTotalPoints?.total }, { x: 'Tổng điểm tối đa của bảng hỏi', y: criteriaTotalPoints?.maxPoint - criteriaTotalPoints?.total }]}
                                        legendData={[{ name: `Tổng điểm hiện tại của bạn: ${criteriaTotalPoints?.total} điểm` }, { name: `Tổng điểm tối đa của bảng hỏi: ${criteriaTotalPoints?.maxPoint} điểm` }]}
                                        legendOrientation="vertical"
                                        legendPosition="right"
                                        padding={{
                                            bottom: 20,
                                            left: 20,
                                            right: 260, // Adjusted to accommodate legend
                                            top: isResponsive ? 100 : 20
                                        }}
                                        subTitle={`trên tổng ${criteriaTotalPoints?.maxPoint}`}
                                        title={criteriaTotalPoints?.total.toString()}
                                        colorScale={colorUINNOVATE}
                                        width={400}
                                    />
                                }
                                {userType === 'UIMPACT' &&
                                    <ChartDonut
                                        constrainToVisibleArea={true}
                                        data={[{ x: 'Tổng điểm hiện tại của bạn', y: criteriaTotalPoints?.total }, { x: 'Tổng điểm tối đa của bảng hỏi', y: criteriaTotalPoints?.maxPoint - criteriaTotalPoints?.total }]}
                                        legendData={[{ name: `Tổng điểm hiện tại của bạn: ${criteriaTotalPoints?.total} điểm` }, { name: `Tổng điểm tối đa của bảng hỏi: ${criteriaTotalPoints?.maxPoint} điểm` }]}
                                        legendOrientation="vertical"
                                        legendPosition="right"
                                        padding={{
                                            bottom: 20,
                                            left: 20,
                                            right: 260, // Adjusted to accommodate legend
                                            top: isResponsive ? 100 : 20
                                        }}
                                        subTitle={`trên tổng ${criteriaTotalPoints?.maxPoint}`}
                                        title={criteriaTotalPoints?.total.toString()}
                                        colorScale={colorUIMPACT}
                                        width={400}
                                    />
                                }
                                {userType === 'PINNOVATE' &&
                                    <ChartDonut
                                        constrainToVisibleArea={true}
                                        data={[{ x: 'Tổng điểm hiện tại của bạn', y: criteriaTotalPoints?.total }, { x: 'Tổng điểm tối đa của bảng hỏi', y: criteriaTotalPoints?.maxPoint - criteriaTotalPoints?.total }]}
                                        legendData={[{ name: `Tổng điểm hiện tại của bạn: ${criteriaTotalPoints?.total} điểm` }, { name: `Tổng điểm tối đa của bảng hỏi: ${criteriaTotalPoints?.maxPoint} điểm` }]}
                                        legendOrientation="vertical"
                                        legendPosition="right"
                                        padding={{
                                            bottom: 20,
                                            left: 20,
                                            right: 260, // Adjusted to accommodate legend
                                            top: isResponsive ? 100 : 20
                                        }}
                                        subTitle={`trên tổng ${criteriaTotalPoints?.maxPoint}`}
                                        title={criteriaTotalPoints?.total.toString()}
                                        colorScale={colorPINNOVATE}
                                        width={400}
                                    />
                                }
                                {/* {(pointOfResult && criteriaResult[0] && criteriaId && criteriaTotalPoints && maxCountInCriteria && userType === 'PINNOVATE') &&
                                    <div className='result-chart' style={{ width: '600px' }}>
                                        <div className='title-result-chart'>Thống kê số lượng câu trả lời</div>
                                        <div className='x-axis'>Số câu trả lời</div>
                                        <div className='y-axis'>Số điểm</div>
                                        <Chart
                                            containerComponent={<ChartVoronoiContainer labels={({ datum }) => `${datum.name}: ${datum.y}`} constrainToVisibleArea />}
                                            domain={{ y: [0, maxCountInCriteria + 1] }}
                                            domainPadding={{ x: [30, 25] }}
                                            height={300}
                                            padding={{
                                                bottom: 50,
                                                left: 50,
                                                right: 200, // Adjusted to accommodate legend
                                                top: 50
                                            }}
                                            width={700}
                                        >
                                            <ChartBar data={
                                                [
                                                    { name: '1', x: '1', y: criteriaResult[0].count },
                                                    { name: '2', x: '2', y: criteriaResult[1].count },
                                                    { name: '3', x: '3', y: criteriaResult[2].count },
                                                    { name: '4', x: '4', y: criteriaResult[3].count },
                                                    { name: '5', x: '5', y: criteriaResult[4].count },
                                                    { name: '6', x: '6', y: criteriaResult[5].count },
                                                    { name: '7', x: '7', y: criteriaResult[6].count },
                                                    { name: '8', x: '8', y: criteriaResult[7].count },
                                                    { name: '9', x: '9', y: criteriaResult[8].count },
                                                    { name: '10', x: '10', y: criteriaResult[9].count },
                                                ]
                                            }
                                            />

                                        </Chart>
                                    </div>
                                }
                                {(pointOfResult && criteriaResult[0] && criteriaId && criteriaTotalPoints && maxCountInCriteria && userType === 'UIMPACT') &&
                                    <div className='result-chart' style={{ width: '600px' }}>
                                        <div className='title-result-chart'>Thống kê số lượng câu trả lời</div>
                                        <div className='x-axis'>Số câu trả lời</div>
                                        <div className='y-axis'>Số điểm</div>
                                        <Chart
                                            containerComponent={<ChartVoronoiContainer labels={({ datum }) => `${datum.name}: ${datum.y}`} constrainToVisibleArea />}
                                            domain={{ y: [0, maxCountInCriteria + 1] }}
                                            domainPadding={{ x: [30, 25] }}
                                            height={300}
                                            padding={{
                                                bottom: 50,
                                                left: 50,
                                                right: 200, // Adjusted to accommodate legend
                                                top: 50
                                            }}
                                            width={700}
                                        >
                                            <ChartBar data={
                                                [
                                                    { name: '1', x: '1', y: criteriaResult[0].count },
                                                    { name: '2', x: '2', y: criteriaResult[1].count },
                                                    { name: '3', x: '3', y: criteriaResult[2].count },
                                                    { name: '4', x: '4', y: criteriaResult[3].count },
                                                    { name: '5', x: '5', y: criteriaResult[4].count },
                                                ]
                                            }
                                            />
                                        </Chart>
                                    </div>
                                }
                                {(pointOfResult && criteriaResult[0] && criteriaId && criteriaTotalPoints && maxCountInCriteria && userType === 'UINNOVATE') &&
                                    <div className='result-chart' style={{ width: '600px' }}>
                                        <div className='title-result-chart'>Thống kê số lượng câu trả lời</div>
                                        <div className='x-axis'>Số câu trả lời</div>
                                        <div className='y-axis'>Số điểm</div>
                                        <Chart
                                            containerComponent={<ChartVoronoiContainer labels={({ datum }) => `${datum.name}: ${datum.y}`} constrainToVisibleArea />}
                                            domain={{ y: [0, maxCountInCriteria + 1] }}
                                            domainPadding={{ x: [30, 25] }}
                                            height={300}
                                            padding={{
                                                bottom: 50,
                                                left: 50,
                                                right: 200, // Adjusted to accommodate legend
                                                top: 50
                                            }}
                                            width={700}
                                        >
                                            <ChartBar data={
                                                [
                                                    { name: 'Hoàn toàn đồng ý', x: 'Hoàn toàn đồng ý', y: criteriaResult[0].count },
                                                    { name: 'Đồng ý một phần', x: 'Đồng ý một phần', y: criteriaResult[1].count },
                                                    { name: 'Không đồng ý', x: 'Không đồng ý', y: criteriaResult[2].count },
                                                ]
                                            }
                                            />
                                        </Chart>
                                    </div>
                                } */}
                            </div>
                        }
                    </div>
                </div>
                <div className='total-score-right'>
                    {(pointOfResult && !criteriaId && userType === 'UINNOVATE' && tmpPointsResults && tmpSystemStatistics && tmp1PointsResults && tmp1SystemStatistics) &&
                        <div className='total-score-right-content'>
                            {/* <div className='content-score'>
                                <div className='title-content-score'>Tổng điểm các phương diện</div>
                                <div className='x-axis'>Số điểm</div>
                                <div className='y-axis'>Phương diện</div>
                                <div className='label'>
                                    <div className='label-item'>
                                        <div className='label-color' style={{ backgroundColor: colorUINNOVATE[0] }}></div>
                                        <div className='label-name'>Điểm của bạn</div>
                                    </div>
                                    <div className='label-item'>
                                        <div className='label-color' style={{ backgroundColor: colorUINNOVATE[1] }}></div>
                                        <div className='label-name'>Điểm TB của hệ thống</div>
                                    </div>
                                </div>
                                <Chart
                                    containerComponent={<ChartVoronoiContainer labels={({ datum }) => `${datum.y}`} constrainToVisibleArea />}
                                    domain={{ y: [0, maxTotalPointAllCriteria + 10] }}
                                    domainPadding={{ x: [30, 25] }}
                                    height={300}
                                    padding={{
                                        bottom: 50,
                                        left: 50,
                                        right: isResponsive ? 30 : 200, // Adjusted to accommodate legend
                                        top: 100
                                    }}
                                    width={isResponsive ? widthSubChart : widthChart}
                                >
                                    <ChartGroup offset={30}>

                                        <ChartBar
                                            data={
                                                tmpPointsResults
                                            }
                                            style={{ data: { fill: colorUINNOVATE[0] } }}
                                        />
                                        <ChartBar data={
                                            tmpSystemStatistics
                                        }
                                            style={{ data: { fill: colorUINNOVATE[1] } }}
                                        />
                                    </ChartGroup>

                                </Chart>
                            </div> */}
                            <div className='content-score'>
                                <div className='title-content-score'>Điểm trung bình của từng phương diện </div>
                                <div className='x-axis'>Số điểm</div>
                                <div className='y-axis'>Phương diện</div>
                                <div className='label'>
                                    <div className='label-item'>
                                        <div className='label-color' style={{ backgroundColor: colorUINNOVATE[0] }}></div>
                                        <div className='label-name'>Điểm của bạn</div>
                                    </div>
                                    <div className='label-item'>
                                        <div className='label-color' style={{ backgroundColor: colorUINNOVATE[1] }}></div>
                                        <div className='label-name'>Điểm TB của hệ thống</div>
                                    </div>

                                </div>

                                <div style={{
                                    position: 'absolute',
                                    right: 10,
                                    top: 10,
                                    fontWeight: 'bold',
                                    fontSize: 16,
                                    color: colorUINNOVATE[0]
                                }}
                                    title="Năm đánh giá phương diện"
                                >
                                    {currentYear}
                                </div>
                                <Chart
                                    containerComponent={<ChartVoronoiContainer labels={({ datum }) => `${datum.y.toFixed(2)}`} constrainToVisibleArea />}
                                    domain={{ y: [0, 5] }}
                                    domainPadding={{ x: [30, 25] }}
                                    height={300}
                                    padding={{
                                        bottom: 50,
                                        left: 50,
                                        right: isResponsive ? 30 : 200, // Adjusted to accommodate legend
                                        top: 100
                                    }}
                                    width={isResponsive ? widthSubChart : widthChart}
                                >

                                    <ChartGroup offset={30}>

                                        <ChartBar data={
                                            tmp1PointsResults
                                        }
                                            style={{ data: { fill: colorUINNOVATE[0] } }}

                                        />
                                        <ChartBar data={
                                            tmp1SystemStatistics
                                        }
                                            style={{ data: { fill: colorUINNOVATE[1] } }}
                                        />
                                    </ChartGroup>

                                </Chart>
                            </div>
                            <div className='content-score'>
                                <div className='title-content-score'>Điểm phương diện theo thời gian </div>
                                <div className='x-axis'>Số điểm</div>
                                <div className='y-axis'>Phương diện</div>
                                <div className='label'
                                    style={{
                                        left: '40%',
                                    }}
                                >
                                    {
                                        selectedYear !== '2024' &&
                                        <div className='label-item'>
                                            <div className='label-color' style={{ backgroundColor: colorUINNOVATE[0] }}></div>
                                            <div className='label-name'>2023</div>
                                        </div>
                                    }

                                    {
                                        selectedYear !== '2023' &&
                                        <div className='label-item'>
                                            <div className='label-color' style={{ backgroundColor: colorUINNOVATE[1] }}></div>
                                            <div className='label-name'>2024</div>
                                        </div>
                                    }


                                </div>
                                <div className="filters" style={{
                                    position: 'absolute',
                                    display: 'flex',
                                    gap: '1em',
                                    top: '15px',
                                    right: '5%'
                                }}>
                                    <Select
                                        // style={{ width: 200 }}
                                        value={evaluationType}
                                        onChange={setEvaluationType}
                                    >
                                        <Option value="user">Đánh giá của bạn</Option>
                                        <Option value="system">Đánh giá hệ thống</Option>
                                    </Select>

                                    <Select
                                        id='select-criteria'
                                        className='select-criteria'
                                        placeholder="Chọn phương diện"
                                        onChange={
                                            (value) => {
                                                setSelectedCriteria(value);
                                            }
                                        }
                                        value={selectedCriteria ? selectedCriteria : 'Chọn phương diện'}
                                        allowClear
                                        style={{ width: 180 }}
                                    >
                                        {criteriaLst.map((index) => (
                                            <Option value={index.name}>{index.name}</Option>
                                        ))}
                                    </Select>

                                    <Select
                                        style={{ width: 100 }}
                                        onChange={setSelectedYear}
                                        placeholder="Chọn năm"
                                        allowClear
                                    >
                                        <Option value="2023">2023</Option>
                                        <Option value="2024">2024</Option>
                                    </Select>
                                </div>

                                {/* <div style={{
                                    position: 'absolute',
                                    right: 10,
                                    top: 10,
                                    fontWeight: 'bold',
                                    fontSize: 16,
                                    color: 'red'
                                }}
                                    title="Năm đánh giá phương diện"
                                >
                                    {currentYear}
                                </div> */}
                                <Chart
                                    containerComponent={<ChartVoronoiContainer labels={({ datum }) => `${datum.y.toFixed(2)}`} constrainToVisibleArea />}
                                    domain={{ y: [0, 5] }}
                                    domainPadding={{ x: [30, 25] }}
                                    height={300}
                                    padding={{
                                        bottom: 50,
                                        left: 50,
                                        right: isResponsive ? 30 : 200, // Adjusted to accommodate legend
                                        top: 100
                                    }}
                                    width={isResponsive ? widthSubChart : widthChart}
                                >
                                    <ChartGroup offset={30}>

                                        {
                                            evaluationType === 'user' && selectedYear !== '2024' && tmp2PointsResults.length > 0 &&
                                            <ChartBar data={
                                                tmp2PointsResults
                                            }
                                                style={{ data: { fill: colorUINNOVATE[0] } }}
                                            />
                                        }

                                        {
                                            evaluationType === 'user' && selectedYear !== '2023' && tmp3PointsResults.length > 0 &&
                                            <ChartBar data={
                                                tmp3PointsResults
                                            }
                                                style={{ data: { fill: colorUINNOVATE[1] } }}
                                            />
                                        }
                                        {
                                            evaluationType === 'system' && selectedYear !== '2024' && tmp2SystemStatistics.length > 0 &&
                                            <ChartBar data={
                                                tmp2SystemStatistics
                                            }
                                                style={{ data: { fill: colorUINNOVATE[0] } }}
                                            />
                                        }
                                        {
                                            evaluationType === 'system' && selectedYear !== '2023' && tmp3SystemStatistics.length > 0 &&
                                            <ChartBar data={
                                                tmp3SystemStatistics
                                            }
                                                style={{ data: { fill: colorUINNOVATE[1] } }}
                                            />
                                        }
                                    </ChartGroup>
                                </Chart>
                            </div>
                            <div className='content-score'>
                                <div className='title-content-score'>Thống kê kết quả đánh giá của trường </div>
                                <div className='main-content-chart'
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-around',
                                        alignItems: 'center',
                                        width: '100%',
                                        height: '100%',
                                        marginTop: '50px'
                                    }}
                                >
                                    <div style={{
                                        position: 'absolute',
                                        right: 10,
                                        top: 10,
                                        fontWeight: 'bold',
                                        fontSize: 16,
                                        color: colorUINNOVATE[0]
                                    }}
                                        title="Năm đánh giá phương diện"
                                    >
                                        {currentYear}
                                    </div>

                                    {(lstData && dataDonut) &&
                                        <div className="charts">
                                            <div
                                                style={{
                                                    marginBottom: '40px',
                                                }}
                                            >Biểu đồ đánh giá tổng thể của bạn </div>
                                            <div className='chart'>
                                                <div className="chart-polar">
                                                    <PolarAreaChart data={lstData} options={options} />
                                                    <div className="logo-chart">
                                                        <img src={Logo} alt="" />
                                                    </div>
                                                </div>
                                                <div className="chart-dough">
                                                    <DoughnutChart data={dataDonut} options={optionsDonut} />
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    {/* {(lstData && dataDonut) &&
                                        <div className="charts">
                                            <div
                                                style={{
                                                    marginBottom: '40px',
                                                }}
                                            >Biểu đồ trung bình tất cả đánh giá trên hệ thống</div>
                                            <div className='chart'>
                                                <div className="chart-polar">
                                                    <PolarAreaChart data={lstData} options={options} />
                                                    <div className="logo-chart">
                                                        <img src={Logo} alt="" />
                                                    </div>
                                                </div>
                                                <div className="chart-dough">
                                                    <DoughnutChart data={dataDonut} options={optionsDonut} />
                                                </div>

                                                <div className="chart-radar"
                                                    style={{
                                                        width: 480,
                                                        height: 480,
                                                        // zIndex: 1,
                                                        position: 'absolute',
                                                        top: '50%',
                                                        left: '50%',
                                                        transform: 'translate(-50%, -50%)'
                                                    }}
                                                >
                                                    <RadarChart data={dataRadio} options={optionsRadio} />
                                                </div>
                                            </div>
                                        </div>
                                    } */}
                                    {doubleChartData.length > 0 && (
                                        <div className="charts">
                                            <div >
                                                Biểu đồ trung bình tất cả đánh giá trên hệ thống
                                            </div>
                                            <div className="radar-chart-double" style={{ width: 580, height: 580 }}>
                                                <RadarAmChartDouble
                                                    data={doubleChartData}
                                                    colors={{
                                                        color1: "rgba(254, 246, 169, 1)", // Màu cho data radar
                                                        color2: "rgba(252, 89, 126, 1)"   // Màu cho data trung bình
                                                    }}
                                                />
                                            </div>
                                            <div className='labels'>
                                                <div className='labels__item'>
                                                    <div className='labels__item__color' style={{ backgroundColor: 'rgba(254, 246, 169, 1)' }}></div>
                                                    <div className='labels__item__text'>Đánh giá của hệ thống</div>
                                                </div>
                                                <div className='labels__item'>
                                                    <div className='labels__item__color' style={{ backgroundColor: 'rgba(252, 89, 126, 1)' }}></div>
                                                    <div className='labels__item__text'>Đánh giá trung bình</div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                    }
                    {(pointOfResult && !criteriaId && userType === 'PINNOVATE' && tmpPointsResults && tmpSystemStatistics && tmp1PointsResults && tmp1SystemStatistics) &&
                        <div className='total-score-right-content'>
                            {/* <div className='content-score'>
                                <div className='title-content-score'>Tổng điểm các phương diện</div>
                                <div className='x-axis'>Số điểm</div>
                                <div className='y-axis'>Phương diện</div>
                                <div className='label'>
                                    <div className='label-item'>
                                        <div className='label-color' style={{ backgroundColor: colorPINNOVATE[0] }}></div>
                                        <div className='label-name'>Điểm của bạn</div>
                                    </div>
                                    <div className='label-item'>
                                        <div className='label-color' style={{ backgroundColor: colorPINNOVATE[1] }}></div>
                                        <div className='label-name'>Điểm 5 năm trước</div>
                                    </div>
                                </div>

                                <Chart
                                    containerComponent={<ChartVoronoiContainer labels={({ datum }) => `${datum.y}`} constrainToVisibleArea />}
                                    domain={{ y: [0, maxTotalPointAllCriteria + 50] }}
                                    domainPadding={{ x: [30, 25] }}
                                    height={300}
                                    padding={{
                                        bottom: 50,
                                        left: 50,
                                        right: isResponsive ? 30 : 200, // Adjusted to accommodate legend
                                        top: 50
                                    }}
                                    width={isResponsive ? widthSubChart : widthChart}

                                >
                                    <ChartGroup offset={30}>

                                        <ChartBar data={
                                            tmpPointsResults
                                        }
                                            style={{ data: { fill: colorPINNOVATE[0] } }}

                                        />
                                        <ChartBar data={
                                            tmpSystemStatistics
                                        }
                                            style={{ data: { fill: colorPINNOVATE[1] } }}
                                        />
                                    </ChartGroup>

                                </Chart>
                            </div> */}
                            <div className='content-score'>
                                <div className='title-content-score'>Điểm trung bình của từng phương diện </div>
                                <div className='x-axis'>Số điểm</div>
                                <div className='y-axis'>Phương diện</div>
                                <div className='label'>
                                    <div className='label-item'>
                                        <div className='label-color' style={{ backgroundColor: colorPINNOVATE[0] }}></div>
                                        <div className='label-name'>Điểm của bạn</div>
                                    </div>
                                    <div className='label-item'>
                                        <div className='label-color' style={{ backgroundColor: colorPINNOVATE[1] }}></div>
                                        <div className='label-name'>Điểm 5 năm trước</div>
                                    </div>
                                </div>

                                <div style={{
                                    position: 'absolute',
                                    right: 10,
                                    top: 10,
                                    fontWeight: 'bold',
                                    fontSize: 16,
                                    color: colorPINNOVATE[0]
                                }}
                                    title="Năm đánh giá phương diện"
                                >
                                    {currentYear}
                                </div>
                                <Chart
                                    containerComponent={<ChartVoronoiContainer labels={({ datum }) => `${datum.y.toFixed(2)}`} constrainToVisibleArea />}
                                    domain={{ y: [0, 10] }}
                                    domainPadding={{ x: [30, 25] }}
                                    height={300}
                                    padding={{
                                        bottom: 50,
                                        left: 50,
                                        right: isResponsive ? 30 : 200, // Adjusted to accommodate legend
                                        top: 50
                                    }}
                                    width={isResponsive ? widthSubChart : widthChart}

                                >
                                    <ChartGroup offset={30}>
                                        <ChartBar data={
                                            tmp1PointsResults
                                        }
                                            style={{ data: { fill: colorPINNOVATE[0] } }}

                                        />
                                        <ChartBar data={
                                            tmp1SystemStatistics
                                        }
                                            style={{ data: { fill: colorPINNOVATE[1] } }}
                                        />
                                    </ChartGroup>
                                </Chart>
                            </div>

                            <div className='content-score'>
                                <div className='title-content-score'>Điểm phương diện theo thời gian </div>
                                <div className='x-axis'>Số điểm</div>
                                <div className='y-axis'>Phương diện</div>
                                <div className='label'
                                    style={{
                                        left: '40%',
                                    }}
                                >
                                    {
                                        selectedYear !== '2024' &&
                                        <div className='label-item'>
                                            <div className='label-color' style={{ backgroundColor: colorPINNOVATE[0] }}></div>
                                            <div className='label-name'>2023</div>
                                        </div>
                                    }

                                    {
                                        selectedYear !== '2023' &&
                                        <div className='label-item'>
                                            <div className='label-color' style={{ backgroundColor: colorPINNOVATE[1] }}></div>
                                            <div className='label-name'>2024</div>
                                        </div>
                                    }


                                </div>
                                <div className="filters" style={{
                                    position: 'absolute',
                                    display: 'flex',
                                    gap: '1em',
                                    top: '15px',
                                    right: '5%'
                                }}>
                                    <Select
                                        // style={{ width: 200 }}
                                        value={evaluationType}
                                        onChange={setEvaluationType}
                                    >
                                        <Option value="user">Đánh giá của bạn</Option>
                                        <Option value="system">Đánh giá hệ thống</Option>
                                    </Select>

                                    <Select
                                        id='select-criteria'
                                        className='select-criteria'
                                        placeholder="Chọn phương diện"
                                        onChange={
                                            (value) => {
                                                setSelectedCriteria(value);
                                            }
                                        }
                                        value={selectedCriteria ? selectedCriteria : 'Chọn phương diện'}
                                        allowClear
                                        style={{ width: 180 }}
                                    >
                                        {criteriaLst.map((index) => (
                                            <Option value={index.name}>{index.name}</Option>
                                        ))}
                                    </Select>

                                    <Select
                                        style={{ width: 100 }}
                                        onChange={setSelectedYear}
                                        placeholder="Chọn năm"
                                        allowClear
                                    >
                                        <Option value="2023">2023</Option>
                                        <Option value="2024">2024</Option>
                                    </Select>
                                </div>
                                <Chart
                                    containerComponent={<ChartVoronoiContainer labels={({ datum }) => `${datum.y.toFixed(2)}`} constrainToVisibleArea />}
                                    domain={{ y: [0, 5] }}
                                    domainPadding={{ x: [30, 25] }}
                                    height={300}
                                    padding={{
                                        bottom: 50,
                                        left: 50,
                                        right: isResponsive ? 30 : 200, // Adjusted to accommodate legend
                                        top: 100
                                    }}
                                    width={isResponsive ? widthSubChart : widthChart}
                                >
                                    <ChartGroup offset={30}>

                                        {
                                            evaluationType === 'user' && selectedYear !== '2024' &&
                                            <ChartBar data={
                                                tmp2PointsResults
                                            }
                                                style={{ data: { fill: colorPINNOVATE[0] } }}
                                            />
                                        }

                                        {
                                            evaluationType === 'user' && selectedYear !== '2023' &&
                                            <ChartBar data={
                                                tmp3PointsResults
                                            }
                                                style={{ data: { fill: colorPINNOVATE[1] } }}
                                            />
                                        }
                                        {
                                            evaluationType === 'system' && selectedYear !== '2024' &&
                                            <ChartBar data={
                                                tmp2SystemStatistics
                                            }
                                                style={{ data: { fill: colorPINNOVATE[0] } }}
                                            />
                                        }
                                        {
                                            evaluationType === 'system' && selectedYear !== '2023' &&
                                            <ChartBar data={
                                                tmp3SystemStatistics
                                            }
                                                style={{ data: { fill: colorPINNOVATE[1] } }}
                                            />
                                        }
                                    </ChartGroup>
                                </Chart>
                            </div>

                            <div className='content-score'>
                                <div className='title-content-score'>Thống kê kết quả đánh giá của trường </div>
                                <div className='main-content-chart'
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-around',
                                        alignItems: 'center',
                                        width: '100%',
                                        height: '100%',
                                        marginTop: '50px'
                                    }}
                                >
                                    <div style={{
                                        position: 'absolute',
                                        right: 10,
                                        top: 10,
                                        fontWeight: 'bold',
                                        fontSize: 16,
                                        color: colorPINNOVATE[0]
                                    }}
                                        title="Năm đánh giá phương diện"
                                    >
                                        {currentYear}
                                    </div>

                                    {(lstData && dataDonut) &&
                                        <div className="charts">
                                            <div
                                                style={{
                                                    marginBottom: '40px',
                                                }}
                                            >Biểu đồ đánh giá tổng thể của bạn </div>
                                            <div className='chart'>
                                                <div className="chart-polar">
                                                    <PolarAreaChart data={lstData} options={options} />
                                                    <div className="logo-chart">
                                                        <img src={Logo} alt="" />
                                                    </div>
                                                </div>
                                                <div className="chart-dough">
                                                    <DoughnutChart data={dataDonut} options={optionsDonut} />
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    {/* {(lstData && dataDonut) &&
                                        <div className="charts">
                                            <div
                                                style={{
                                                    marginBottom: '40px',
                                                }}
                                            >Biểu đồ trung bình tất cả đánh giá trên hệ thống</div>
                                            <div className='chart'>
                                           
                                                <div className="chart-dough">
                                                    <DoughnutChart data={dataDonut2} options={optionsDonut} />
                                                </div>

                                                <div className="chart-radar"
                                                    style={{
                                                        width: 480,
                                                        height: 480,
                                                        // zIndex: 1,
                                                        position: 'absolute',
                                                        top: '50%',
                                                        left: '50%',
                                                        transform: 'translate(-50%, -50%)'
                                                    }}
                                                >
                                                    <RadarChart data={dataRadio} options={optionsRadio} />
                                                </div>
                                            </div>
                                        </div>
                                    } */}
                                </div>
                            </div>
                        </div>
                    }

                    {(pointOfResult && !criteriaId && userType === 'UIMPACT' && tmpPointsResults && tmpSystemStatistics && tmp1PointsResults && tmp1SystemStatistics) &&
                        <div className='total-score-right-content'>
                            {/* <div className='content-score'>
                                <div className='title-content-score'>Tổng điểm của các phương diện  </div>
                                <div className='x-axis'>Số điểm</div>
                                <div className='y-axis'>Phương diện</div>
                                <div className='label'>
                                    <div className='label-item'>
                                        <div className='label-color' style={{ backgroundColor: colorUIMPACT[0] }}></div>
                                        <div className='label-name'>Điểm của bạn</div>
                                    </div>
                                    <div className='label-item'>
                                        <div className='label-color' style={{ backgroundColor: colorUIMPACT[1] }}></div>
                                        <div className='label-name'>Điểm TB của hệ thống</div>
                                    </div>
                                </div>
                                <div style={{
                                    position: 'absolute',
                                    right: 10,
                                    top: 10,
                                    fontWeight: 'bold',
                                    fontSize: 16,
                                    color: colorUIMPACT[0]
                                }}
                                    title="Năm đánh giá phương diện"
                                >
                                    {currentYear}
                                </div>

                                <Chart
                                    containerComponent={<ChartVoronoiContainer labels={({ datum }) => `${datum.y}`} constrainToVisibleArea />}
                                    domain={{ y: [0, maxTotalPointAllCriteria + 10] }}
                                    domainPadding={{ x: [30, 25] }}
                                    height={300}
                                    padding={{
                                        bottom: 50,
                                        left: 50,
                                        right: isResponsive ? 30 : 200, // Adjusted to accommodate legend
                                        top: 70
                                    }}
                                    width={isResponsive ? widthSubChart : widthChart}
                                >
                                    <ChartGroup offset={30}>

                                        <ChartBar data={
                                            tmpPointsResults
                                        }
                                            style={{ data: { fill: colorUIMPACT[0] } }}

                                        />
                                        <ChartBar data={
                                            tmpSystemStatistics
                                        }
                                            style={{ data: { fill: colorUIMPACT[1] } }}
                                        />
                                    </ChartGroup>
                                </Chart>
                            </div> */}
                            <div className='content-score'>
                                <div className='title-content-score'>Điểm trung bình của từng SDG </div>
                                <div className='x-axis'>Số điểm</div>
                                <div className='y-axis'>Phương diện</div>
                                <div className='label'>
                                    <div className='label-item'>
                                        <div className='label-color' style={{ backgroundColor: colorUIMPACT[0] }}></div>
                                        <div className='label-name'>Điểm của bạn</div>
                                    </div>
                                    <div className='label-item'>
                                        <div className='label-color' style={{ backgroundColor: colorUIMPACT[1] }}></div>
                                        <div className='label-name'>Điểm TB của hệ thống</div>
                                    </div>
                                </div>

                                <div style={{
                                    position: 'absolute',
                                    right: 10,
                                    top: 10,
                                    fontWeight: 'bold',
                                    fontSize: 16,
                                    color: colorUIMPACT[0]
                                }}
                                    title="Năm đánh giá phương diện"
                                >
                                    {currentYear}
                                </div>
                                <Chart
                                    containerComponent={<ChartVoronoiContainer labels={({ datum }) => `${datum.y.toFixed(2)}`} constrainToVisibleArea />}
                                    domain={{ y: [0, 5] }}
                                    domainPadding={{ x: [30, 25] }}
                                    height={300}
                                    padding={{
                                        bottom: 50,
                                        left: 50,
                                        right: isResponsive ? 30 : 200, // Adjusted to accommodate legend
                                        top: 70
                                    }}
                                    width={isResponsive ? widthSubChart : widthChart}
                                >
                                    <ChartGroup offset={30}>

                                        <ChartBar data={
                                            tmp1PointsResults
                                        }
                                            style={{ data: { fill: colorUIMPACT[0] } }}

                                        />
                                        <ChartBar data={
                                            tmp1SystemStatistics
                                        }
                                            style={{ data: { fill: colorUIMPACT[1] } }}
                                        />
                                    </ChartGroup>
                                </Chart>
                            </div>

                            <div className='content-score'>
                                <div className='title-content-score'>Điểm phương diện theo thời gian </div>
                                <div className='x-axis'>Số điểm</div>
                                <div className='y-axis'>Phương diện</div>
                                <div className='label'
                                    style={{
                                        left: '40%',
                                    }}
                                >
                                    {
                                        selectedYear !== '2024' &&
                                        <div className='label-item'>
                                            <div className='label-color' style={{ backgroundColor: colorUIMPACT[0] }}></div>
                                            <div className='label-name'>2023</div>
                                        </div>
                                    }

                                    {
                                        selectedYear !== '2023' &&
                                        <div className='label-item'>
                                            <div className='label-color' style={{ backgroundColor: colorUIMPACT[1] }}></div>
                                            <div className='label-name'>2024</div>
                                        </div>
                                    }


                                </div>
                                <div className="filters" style={{
                                    position: 'absolute',
                                    display: 'flex',
                                    gap: '1em',
                                    top: '15px',
                                    right: '5%'
                                }}>
                                    <Select
                                        // style={{ width: 200 }}
                                        value={evaluationType}
                                        onChange={setEvaluationType}
                                    >
                                        <Option value="user">Đánh giá của bạn</Option>
                                        <Option value="system">Đánh giá hệ thống</Option>
                                    </Select>

                                    <Select
                                        id='select-criteria'
                                        className='select-criteria'
                                        placeholder="Chọn phương diện"
                                        onChange={
                                            (value) => {
                                                setSelectedCriteria(value);
                                            }
                                        }
                                        value={selectedCriteria ? selectedCriteria : 'Chọn phương diện'}
                                        allowClear
                                        style={{ width: 180 }}
                                    >
                                        {criteriaLst.map((index) => (
                                            <Option value={index.name}>{index.name}</Option>
                                        ))}
                                    </Select>

                                    <Select
                                        style={{ width: 100 }}
                                        onChange={setSelectedYear}
                                        placeholder="Chọn năm"
                                        allowClear
                                    >
                                        <Option value="2023">2023</Option>
                                        <Option value="2024">2024</Option>
                                    </Select>
                                </div>
                                <Chart
                                    containerComponent={<ChartVoronoiContainer labels={({ datum }) => `${datum.y.toFixed(2)}`} constrainToVisibleArea />}
                                    domain={{ y: [0, 5] }}
                                    domainPadding={{ x: [30, 25] }}
                                    height={300}
                                    padding={{
                                        bottom: 50,
                                        left: 50,
                                        right: isResponsive ? 30 : 200, // Adjusted to accommodate legend
                                        top: 100
                                    }}
                                    width={isResponsive ? widthSubChart : widthChart}
                                >
                                    <ChartGroup offset={30}>

                                        {
                                            evaluationType === 'user' && selectedYear !== '2024' &&
                                            <ChartBar data={
                                                tmp2PointsResults
                                            }
                                                style={{ data: { fill: colorUIMPACT[0] } }}
                                            />
                                        }

                                        {
                                            evaluationType === 'user' && selectedYear !== '2023' &&
                                            <ChartBar data={
                                                tmp3PointsResults
                                            }
                                                style={{ data: { fill: colorUIMPACT[1] } }}
                                            />
                                        }
                                        {
                                            evaluationType === 'system' && selectedYear !== '2024' &&
                                            <ChartBar data={
                                                tmp2SystemStatistics
                                            }
                                                style={{ data: { fill: colorUIMPACT[0] } }}
                                            />
                                        }
                                        {
                                            evaluationType === 'system' && selectedYear !== '2023' &&
                                            <ChartBar data={
                                                tmp3SystemStatistics
                                            }
                                                style={{ data: { fill: colorUIMPACT[1] } }}
                                            />
                                        }
                                    </ChartGroup>
                                </Chart>
                            </div>

                            <div className='content-score'>
                                <div className='title-content-score'>Thống kê kết quả đánh giá của trường </div>
                                <div className='main-content-chart'
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-around',
                                        alignItems: 'center',
                                        width: '100%',
                                        height: '100%',
                                        marginTop: '50px'
                                    }}
                                >

                                    <div style={{
                                        position: 'absolute',
                                        right: 10,
                                        top: 10,
                                        fontWeight: 'bold',
                                        fontSize: 16,
                                        color: colorUIMPACT[0]
                                    }}
                                        title="Năm đánh giá phương diện"
                                    >
                                        {currentYear}
                                    </div>
                                    <Spin spinning={!lstData || !dataDonut} size='large'>
                                        {(lstData && dataDonut) &&
                                            <div className="charts">
                                                <div className='chart'>
                                                    <div className="chart-polar">
                                                        <PolarAreaChart data={lstData} options={options} />
                                                        <div className="logo-chart">
                                                            <img src={Logo} alt="" />
                                                        </div>
                                                    </div>
                                                    <div className="chart-dough">
                                                        <DoughnutChart data={dataDonut} options={optionsDonut} />
                                                    </div>
                                                </div>

                                                {/* <div className='labels'>
                                            {
                                                systemStatistics?.data?.listAverageOfCriterias?.map((item: any, index: any) => {
                                                    return (
                                                        <div className='labels__item' key={index}>
                                                            <div className='labels__item__color' style={{ backgroundColor: lstColor[index] }}></div>
                                                            <div className='labels__item__text'>
                                                                {item.criteriaName}
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div> */}
                                            </div>
                                        }

                                        {/* {(lstData && dataDonut) &&
                                            <div className="charts">
                                                <div
                                                    style={{
                                                        marginBottom: '40px',
                                                    }}
                                                >Biểu đồ trung bình tất cả đánh giá trên hệ thống</div>
                                                <div className='chart'>

                                                    <div className="chart-dough">
                                                        <DoughnutChart data={dataDonut2} options={optionsDonut} />
                                                    </div>

                                                    <div className="chart-radar"
                                                        style={{
                                                            width: 480,
                                                            height: 480,
                                                            // zIndex: 1,
                                                            position: 'absolute',
                                                            top: '50%',
                                                            left: '50%',
                                                            transform: 'translate(-50%, -50%)'
                                                        }}
                                                    >
                                                        <RadarChart data={dataRadio} options={optionsRadio} />
                                                    </div>
                                                </div>
                                            </div>
                                        } */}
                                    </Spin>

                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
            {criteriaId &&
                <div className='result-answers'>
                    <div className='title-view-test'>
                        <div className='title'>Xem lại phần trả lời</div>
                        {/* <div className='content'>Lorem ipsum dolor sit amet consectetur. Ut amet a amet lacinia etiam integer urna pharetra. Malesuada tristique volutpat semper pharetra mauris dis.</div> */}
                    </div>
                    {tmplstQuestionsByCriteria[0] &&
                        <div className={'taking-test-area result' + userType}>
                            {/* Khi call API se thay doan duoi nay thanh currentSetOfQuestion.content */}
                            <div className='sub-title'>{tmplstQuestionsByCriteria[currentIndex]?.setOfQuestions.name}
                                {userType === 'PINNOVATE' &&
                                    tmplstQuestionsByCriteria[currentIndex]?.setOfQuestions.description
                                }
                            </div>

                            <div className='question-lst' >
                                {
                                    tmplstQuestionsByCriteria[currentIndex].questions?.map((item, indexitem) => ( // Sau nay se thay bang useState currentSetOfQuestion
                                        <>
                                            {userType === 'UIMPACT' ?
                                                <div>
                                                    <div className='content'>{item.question.content}</div>
                                                    {(item.answers.length > 0 && item.answers[0].point > 0) ?
                                                        <div className='options-of-answer'>
                                                            {

                                                                item.answers.map((subitem, indexsubitem) => (
                                                                    <label className='lst-item'>
                                                                        <input
                                                                            style={{ pointerEvents: "none" }}
                                                                            type="radio" className="radio-btn"
                                                                            checked={subitem.isChosen === true}
                                                                            value={subitem.id} id={subitem.id} name={subitem.id.toString()}
                                                                        />
                                                                        <div className="label">{subitem.content}</div>
                                                                    </label>
                                                                ))
                                                            }
                                                        </div>
                                                        :
                                                        <div className='options-of-answer input-answer'>
                                                            {item.answers.length > 0 ?
                                                                <Input
                                                                    className='input-login email-input'
                                                                    placeholder='Nhập số liệu'
                                                                    type='number'
                                                                    id={item.question.id}
                                                                    disabled={true}

                                                                    defaultValue={item.answers[0].content}
                                                                    name={item.answers[0].id.toString()}
                                                                />
                                                                :
                                                                <Input
                                                                    className='input-login email-input'
                                                                    placeholder='Nhập số liệu'
                                                                    type='number'
                                                                    disabled={true}

                                                                />
                                                            }

                                                        </div>
                                                    }
                                                </div>
                                                :
                                                <div>
                                                    <div className='content'>{item.question.content}</div>
                                                    <div className='options-of-answer'>
                                                        {

                                                            item.answers.map((subitem, indexsubitem) => (
                                                                <label className='lst-item'
                                                                >
                                                                    <input
                                                                        style={{ pointerEvents: "none" }}
                                                                        type="radio" className="radio-btn"
                                                                        checked={subitem.isChosen === true}
                                                                        value={subitem.id} id={subitem.id} name={subitem.id.toString()}
                                                                    />
                                                                    <div className="label">{subitem.content}</div>
                                                                </label>
                                                            ))
                                                        }
                                                    </div>
                                                </div>
                                            }
                                        </>
                                    ))
                                }
                            </div>
                            <div className='footer'>
                                {
                                    user?.type !== 'UINNOVATE_SHORT' &&
                                    <Pagination className='pagination' current={currentIndex + 1} total={tmplstQuestionsByCriteria.length * 10} onChange={handlePageChange} showLessItems={true} />
                                }
                            </div>
                        </div>}
                </div>
            }
        </div>

    )
}

export default Result